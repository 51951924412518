import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  Chip,
  Divider,
  useTheme,
  Button,
  IconButton,
  Popover,
  Badge
} from '@mui/material';
import { Icon } from '@iconify/react';
import AddIcon from '@mui/icons-material/Add';
import OpacityIcon from '@mui/icons-material/Opacity';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FilterListIcon from '@mui/icons-material/FilterList';
import dayjs from 'dayjs';

interface ITask {
  _id: string;
  taskName: string;
  taskType: string;
  dueDate: string;
  status: string;
  description: string;
}

interface IHistoricalTask {
  _id: string;
  originalTask: string;
  resident: string;
  taskName: string;
  taskType: string;
  dueDate: string;
  completedDate: string;
  completedBy: string;
  notes?: string;
  status: string;
}

interface TaskGridProps {
  tasks: (ITask | IHistoricalTask)[];
  isHistorical?: boolean;
  onTaskClick?: (task: ITask) => void;
}

const TaskGrid: React.FC<TaskGridProps> = ({
  tasks,
  isHistorical = false,
  onTaskClick
}) => {
  const theme = useTheme();
  const [filteredTaskType, setFilteredTaskType] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const taskTypes = [
    { type: 'pad check', icon: <OpacityIcon /> },
    { type: 'day check', icon: <WbSunnyIcon /> },
    { type: 'night check', icon: <NightsStayIcon /> },
    { type: 'meal', icon: <RestaurantIcon /> },
    { type: 'fluid intake', icon: <OpacityIcon /> },
    { type: 'medication', icon: <MedicalServicesIcon /> },
    { type: 'personal care', icon: <AccessibilityNewIcon /> },
    { type: 'other', icon: <AccessibilityNewIcon /> }
  ];

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'upcoming':
        return theme.palette.info.main;
      case 'pending':
        return theme.palette.warning.main;
      case 'completed':
        return theme.palette.success.main;
      default:
        return theme.palette.grey[500];
    }
  };

  const getTaskIcon = (type: string) => {
    const taskType = taskTypes.find((t) => t.type === type);
    return taskType ? taskType.icon : <AccessibilityNewIcon />;
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleTaskTypeFilter = (taskType: string) => {
    setFilteredTaskType((prevType) =>
      prevType === taskType ? null : taskType
    );
    handleFilterClose();
  };

  const clearFilter = () => {
    setFilteredTaskType(null);
    handleFilterClose();
  };

  const filteredTasks = filteredTaskType
    ? tasks.filter((task) => task.taskType === filteredTaskType)
    : tasks;

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={handleFilterClick} size="large">
          <Badge color="secondary" variant="dot" invisible={!filteredTaskType}>
            <FilterListIcon />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Filter by Task Type
            </Typography>
            <Button
              variant="outlined"
              onClick={clearFilter}
              startIcon={<FilterListIcon />}
              sx={{ mb: 1, width: 'fit-content' }}
            >
              Clear Filter
            </Button>
            <Grid container spacing={1}>
              {taskTypes.map((type) => (
                <Grid item xs={6} md={3} key={type.type}>
                  <Button
                    key={type.type}
                    variant={
                      filteredTaskType === type.type ? 'contained' : 'outlined'
                    }
                    onClick={() => handleTaskTypeFilter(type.type)}
                    startIcon={type.icon}
                    sx={{ mb: 1, width: '100%' }}
                  >
                    {type.type}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Popover>
      </Box>
      <Grid container spacing={2}>
        {filteredTasks.map((task) => (
          <Grid item xs={6} sm={3} md={4} lg={3} key={task._id}>
            <Paper
              elevation={2}
              sx={{
                p: 1,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative',
                overflow: 'hidden',
                cursor: isHistorical ? 'default' : 'pointer',
                '&:hover': {
                  transform: isHistorical ? 'none' : 'scale(1.03)',
                  transition: 'transform 0.2s ease-in-out'
                }
              }}
              onClick={() => {
                if (onTaskClick) onTaskClick(task as ITask);
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                {getTaskIcon(task.taskType)}
                <Typography variant="body2" sx={{ ml: 0.5 }}>
                  {task.taskName}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ my: 0.5 }}>
                <Typography variant="body2" color="textSecondary">
                  {isHistorical
                    ? (task as IHistoricalTask).notes
                    : (task as ITask).description}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mt: 'auto'
                }}
              >
                <Chip
                  size="small"
                  label={task.status}
                  sx={{
                    backgroundColor: getStatusColor(task.status),
                    color: 'white',
                    fontSize: '0.6rem',
                    height: 20
                  }}
                />
                <Chip
                  icon={<Icon icon="ph:clock-duotone" />}
                  label={dayjs(
                    isHistorical
                      ? (task as IHistoricalTask).completedDate
                      : (task as ITask).dueDate
                  ).format('HH:mm')}
                  size="small"
                  sx={{
                    fontSize: '0.6rem',
                    display: { md: 'none', xs: 'inline-flex' }
                  }}
                />
                <Chip
                  icon={<Icon icon="ph:clock-duotone" />}
                  label={dayjs(
                    isHistorical
                      ? (task as IHistoricalTask).completedDate
                      : (task as ITask).dueDate
                  ).format('DD/MM/YYYY HH:mm')}
                  size="small"
                  sx={{
                    fontSize: '0.6rem',
                    display: { md: 'inline-flex', xs: 'none' }
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TaskGrid;
