import React, { useState, useEffect } from 'react';
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray
} from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  Paper,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import type { IShiftPattern } from 'src/interfaces/shift-pattern';
import { useAppSelector } from 'src/redux/hook';

interface TimingGroup {
  startTime: string;
  endTime: string;
}

interface UserTypeRate {
  userType: string;
  weekdayRate: number;
  weekendRate: number;
  holidayRate: number;
}

interface FormData {
  name: string;
  timingGroups: TimingGroup[];
  userTypeRates: UserTypeRate[];
}

interface ShiftTypeDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (shiftType: IShiftPattern) => void;
  selectedShiftType?: IShiftPattern;
}

const userTypes = ['Carer', 'Senior Carer', 'Nurse'];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default
}));

const ShiftTypeDialog: React.FC<ShiftTypeDialogProps> = ({
  open,
  onClose,
  onSave,
  selectedShiftType
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      timingGroups: [{ startTime: '', endTime: '' }],
      userTypeRates: userTypes.map((userType) => ({
        userType,
        weekdayRate: 0,
        weekendRate: 0,
        holidayRate: 0
      }))
    }
  });

  const {
    fields: timingGroupFields,
    append: appendTimingGroup,
    remove: removeTimingGroup
  } = useFieldArray({ control, name: 'timingGroups' });

  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const userState = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (open) {
      if (selectedShiftType) {
        populateForm(selectedShiftType);
        setIsEditing(false);
      } else {
        reset();
        setIsEditing(true);
      }
    }
  }, [open, selectedShiftType, reset]);

  const populateForm = (shiftType: IShiftPattern) => {
    setValue('name', shiftType.name);
    setValue('timingGroups', [
      {
        startTime: shiftType.startTime,
        endTime: shiftType.endTime
      }
    ]);
    setValue('userTypeRates', shiftType.userTypeRates || []);
  };

  const handleSaveShiftType: SubmitHandler<FormData> = (data) => {
    // const newShiftType: any = {
    //   ...(selectedShiftType?._id ? { _id: selectedShiftType._id } : {}),
    //   name: data.name,
    //   startTime: data.timingGroups[0].startTime,
    //   endTime: data.timingGroups[0].endTime,
    //   userTypeRates: data.userTypeRates
    // };

    const newShiftType: any = {
      ...(selectedShiftType?._id ? { _id: selectedShiftType._id } : {}),
      name: data.name,
      userId: selectedShiftType?.userId || '',
      timings: data.timingGroups.flatMap((group) => ({
        careHomeId: userState.currentOrganization._id,
        startTime: group.startTime,
        endTime: group.endTime
      })),
      userTypeRates: data.userTypeRates
    };
    onSave(newShiftType);
    reset();
    setIsEditing(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {selectedShiftType
              ? isEditing
                ? 'Edit Shift Type'
                : 'View Shift Type'
              : 'Add Shift Type'}
          </Typography>
          {selectedShiftType && !isEditing && (
            <IconButton
              color="primary"
              onClick={() => setIsEditing(true)}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSaveShiftType)}>
          <StyledPaper elevation={0}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: 'Shift type name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Shift Type Name"
                      fullWidth
                      variant="outlined"
                      disabled={!isEditing}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </StyledPaper>

          <Typography variant="h6" gutterBottom>
            Shift Timings
          </Typography>
          {timingGroupFields.map((field, index) => (
            <StyledPaper key={field.id} elevation={0}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Controller
                    name={`timingGroups.${index}.startTime`}
                    control={control}
                    rules={{ required: 'Start time is required' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Start Time"
                        fullWidth
                        variant="outlined"
                        type="time"
                        InputLabelProps={{ shrink: true }}
                        disabled={!isEditing}
                        error={!!errors.timingGroups?.[index]?.startTime}
                        helperText={
                          errors.timingGroups?.[index]?.startTime?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={`timingGroups.${index}.endTime`}
                    control={control}
                    rules={{ required: 'End time is required' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="End Time"
                        fullWidth
                        variant="outlined"
                        type="time"
                        InputLabelProps={{ shrink: true }}
                        disabled={!isEditing}
                        error={!!errors.timingGroups?.[index]?.endTime}
                        helperText={
                          errors.timingGroups?.[index]?.endTime?.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </StyledPaper>
          ))}

          <Typography variant="h6" gutterBottom>
            User Type Specific Rates
          </Typography>
          <StyledPaper elevation={0}>
            <Grid container spacing={3}>
              {userTypes.map((userType, index) => (
                <React.Fragment key={userType}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">{userType}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`userTypeRates.${index}.weekdayRate`}
                      control={control}
                      rules={{
                        required: 'Weekday rate is required',
                        min: { value: 0, message: 'Rate must be non-negative' }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Weekday Rate"
                          fullWidth
                          variant="outlined"
                          type="number"
                          inputProps={{ step: '0.01' }}
                          disabled={!isEditing}
                          error={!!errors.userTypeRates?.[index]?.weekdayRate}
                          helperText={
                            errors.userTypeRates?.[index]?.weekdayRate?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`userTypeRates.${index}.weekendRate`}
                      control={control}
                      rules={{
                        required: 'Weekend rate is required',
                        min: { value: 0, message: 'Rate must be non-negative' }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Weekend Rate"
                          fullWidth
                          variant="outlined"
                          type="number"
                          inputProps={{ step: '0.01' }}
                          disabled={!isEditing}
                          error={!!errors.userTypeRates?.[index]?.weekendRate}
                          helperText={
                            errors.userTypeRates?.[index]?.weekendRate?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`userTypeRates.${index}.holidayRate`}
                      control={control}
                      rules={{
                        required: 'Holiday rate is required',
                        min: { value: 0, message: 'Rate must be non-negative' }
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Holiday Rate"
                          fullWidth
                          variant="outlined"
                          type="number"
                          inputProps={{ step: '0.01' }}
                          disabled={!isEditing}
                          error={!!errors.userTypeRates?.[index]?.holidayRate}
                          helperText={
                            errors.userTypeRates?.[index]?.holidayRate?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </StyledPaper>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {isEditing && (
          <Button
            onClick={handleSubmit(handleSaveShiftType)}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ShiftTypeDialog;
