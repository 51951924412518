import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  useTheme,
  Grid,
  Paper,
  Tabs,
  Tab,
  Divider,
  useMediaQuery,
  CircularProgress,
  Switch,
  FormControlLabel
} from '@mui/material';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import OpacityIcon from '@mui/icons-material/Opacity';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PadCheckDialog from './dialogs/pad-check';
import MealFluidDialog from './dialogs/meal';
import MedicationDialog from './dialogs/medication';
import PersonalCareDialog from './dialogs/care';
import WakeAsleepCheckDialog from './dialogs/wakeupasleep';
import ResidentProfileDialog from './dialogs/resident-profile';
import { IResident } from 'src/interfaces/resident';
import InfiniteScroll from 'react-infinite-scroll-component';
import TaskGrid from './task-grid';
import {
  useGetHistoricTasksQuery,
  useGetTasksQuery
} from 'src/redux/@api/resident';
import {
  useCompleteTaskMutation,
  useCreateMultipleTasksMutation,
  useCreateTaskMutation
} from 'src/redux/@api/task';
import { TurnSlightRightSharp } from '@mui/icons-material';
import ResidentTurnDialog from './dialogs/turn';
import ResidentProgressDashboard from './dialogs/charts';
import { useNavigate } from 'react-router';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TaskManagementComponentProps {
  resident: IResident;
}

interface ITaskFrequency {
  type: 'frequent' | 'daily' | 'weekly' | 'monthly';
  interval?: number;
  timeOfDay?: string;
}

interface ITaskData {
  [key: string]: any;
}

interface ITask {
  _id: string;
  resident: string;
  taskName: string;
  description: string;
  taskType: string;
  frequency: ITaskFrequency;
  dueDate: string;
  nextOccurrence: string;
  completedDate?: string;
  completedBy?: string;
  status: 'upcoming' | 'pending' | 'completed';
  notes?: string;
  taskData: ITaskData;
}

interface IHistoricalTask {
  _id: string;
  originalTask: string;
  resident: string;
  taskName: string;
  taskType: string;
  dueDate: string;
  completedDate: string;
  completedBy: string;
  notes?: string;
  status: string;
}

interface INewTask {
  taskName: string;
  description: string;
  taskType: string;
  frequency: ITaskFrequency;
  status: 'upcoming' | 'pending' | 'completed';
}

const taskTypes = [
  { type: 'pad check', icon: <OpacityIcon /> },
  { type: 'day check', icon: <WbSunnyIcon /> },
  { type: 'night check', icon: <NightsStayIcon /> },
  { type: 'turn', icon: <TurnSlightRightSharp /> },
  { type: 'meal', icon: <RestaurantIcon /> },
  { type: 'fluid intake', icon: <OpacityIcon /> },
  { type: 'medication', icon: <MedicalServicesIcon /> },
  { type: 'personal care', icon: <AccessibilityNewIcon /> },
  { type: 'other', icon: <AccessibilityNewIcon /> }
];

const TaskManagementComponent: React.FC<TaskManagementComponentProps> = ({
  resident
}) => {
  const residentId = resident._id;
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newTask, setNewTask] = useState<INewTask>({
    taskName: '',
    description: '',
    taskType: '',
    frequency: { type: 'daily', timeOfDay: '' },
    status: 'upcoming'
  });
  const [resolveDialogOpen, setResolveDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>(
    dayjs().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState<Date>(dayjs().endOf('month').toDate());
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [historicalTasks, setHistoricalTasks] = useState<IHistoricalTask[]>([]);
  const [useTimeInterval, setUseTimeInterval] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [intervalHours, setIntervalHours] = useState(1);

  const { data: tasks, refetch: refetchTasks } = useGetTasksQuery(residentId);
  const [createTask] = useCreateTaskMutation();
  const [createMultipleTasks] = useCreateMultipleTasksMutation();
  const [completeTask] = useCompleteTaskMutation();

  const { data: historicTasksData, refetch: refetchHistoricTasks } =
    useGetHistoricTasksQuery({
      residentId: resident._id,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      page,
      limit: 20
    });

  useEffect(() => {
    if (historicTasksData) {
      if (page === 1) {
        setHistoricalTasks(historicTasksData.tasks);
      } else {
        setHistoricalTasks((prevTasks) => [
          ...prevTasks,
          ...historicTasksData.tasks
        ]);
      }
      setHasMore(historicTasksData.currentPage < historicTasksData.totalPages);
    }
  }, [historicTasksData, page]);

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setHistoricalTasks([]);
    refetchHistoricTasks();
  }, [startDate, endDate, refetchHistoricTasks]);

  const handleAddTask = async () => {
    try {
      const now = dayjs().utc();
      let tasksToCreate: any[] = [];

      if (useTimeInterval && startTime && endTime) {
        let currentTime = dayjs(startTime);
        const endTimeDay = dayjs(endTime);

        while (
          currentTime.isBefore(endTimeDay) ||
          currentTime.isSame(endTimeDay)
        ) {
          tasksToCreate.push({
            ...newTask,
            resident: residentId,
            frequency: {
              ...newTask.frequency,
              timeOfDay: currentTime.format('HH:mm')
            }
          });
          currentTime = currentTime.add(intervalHours, 'hour');
        }
      } else {
        tasksToCreate = selectedTimes.map((time) => {
          const [hours, minutes] = time.split(':').map(Number);
          let taskTime = now.hour(hours).minute(minutes).second(0);

          if (taskTime.isBefore(now)) {
            taskTime = taskTime.add(1, 'day');
          }

          return {
            ...newTask,
            resident: residentId,
            frequency: {
              ...newTask.frequency,
              timeOfDay: time
            }
          };
        });
      }

      if (tasksToCreate.length > 1) {
        await createMultipleTasks(tasksToCreate).unwrap();
      } else if (tasksToCreate.length === 1) {
        await createTask(tasksToCreate[0]).unwrap();
      }

      setDialogOpen(false);
      resetNewTaskForm();
      refetchTasks();
    } catch (error) {
      console.error('Error adding task(s):', error);
    }
  };

  const resetNewTaskForm = () => {
    setNewTask({
      taskName: '',
      description: '',
      taskType: '',
      frequency: { type: 'daily', timeOfDay: '' },
      status: 'upcoming'
    });
    setSelectedTimes([]);
    setUseTimeInterval(false);
    setStartTime(null);
    setEndTime(null);
    setIntervalHours(1);
  };

  const handleCompleteTask = async (taskData: ITaskData) => {
    if (selectedTask) {
      try {
        await completeTask({ taskId: selectedTask._id, taskData }).unwrap();
        setResolveDialogOpen(false);
        setSelectedTask(null);
        refetchTasks();
        refetchHistoricTasks();
      } catch (error) {
        console.error('Error completing task:', error);
      }
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'upcoming':
        return theme.palette.info.main;
      case 'pending':
        return theme.palette.warning.main;
      case 'completed':
        return theme.palette.success.main;
      default:
        return theme.palette.grey[500];
    }
  };

  const getTaskIcon = (type: string) => {
    const taskType = taskTypes.find((t) => t.type === type);
    return taskType ? taskType.icon : <AccessibilityNewIcon />;
  };

  const filteredTasks = tasks?.filter((task) => {
    if (tabValue === 0) return true;
    if (tabValue === 1) return task.status === 'pending';
    return false;
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [tempSelectedTime, setTempSelectedTime] = useState<Date | null>(null);

  const handleTimeChange = (newValue: Date | null) => {
    setTempSelectedTime(newValue);
  };

  const handleAddTime = () => {
    if (tempSelectedTime) {
      const timeString = tempSelectedTime.toTimeString().slice(0, 5);
      if (!selectedTimes.includes(timeString)) {
        setSelectedTimes([...selectedTimes, timeString]);
      }
      setTempSelectedTime(null);
    }
  };

  const renderTaskCard = (
    task: ITask | IHistoricalTask,
    isHistorical: boolean = false
  ) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={task._id}>
      <Paper
        elevation={2}
        sx={{
          p: 1,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          overflow: 'hidden',
          cursor: isHistorical ? 'default' : 'pointer',
          '&:hover': {
            transform: isHistorical ? 'none' : 'scale(1.03)',
            transition: 'transform 0.2s ease-in-out'
          }
        }}
        onClick={() => {
          if (
            !isHistorical &&
            (task.status === 'pending' || task.status === 'upcoming')
          ) {
            handleResolveTask(task as ITask);
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          {getTaskIcon(task.taskType)}
          <Typography
            variant="body2"
            sx={{
              ml: 0.5,
              fontSize: isMobile ? '0.7rem' : '0.875rem'
            }}
          >
            {task.taskName}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ my: 0.5, display: { xs: 'none', sm: 'block' } }}>
          <Typography variant="body2" color="textSecondary">
            {isHistorical ? task.notes : (task as ITask).description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 'auto',
            [theme.breakpoints.down('sm')]: { display: 'block' }
          }}
        >
          <Chip
            size="small"
            label={task.status}
            sx={{
              backgroundColor: getStatusColor(task.status),
              color: 'white',
              fontSize: '0.6rem',
              height: 20
            }}
          />
          <Chip
            icon={<Icon icon="ph:clock-duotone" />}
            label={dayjs(isHistorical ? task.completedDate : task.dueDate)
              .local()
              .format('DD/MM/YYYY HH:mm')}
            size="small"
            sx={{ fontSize: '0.6rem', marginLeft: 'auto' }}
          />
        </Box>
      </Paper>
    </Grid>
  );

  const loadMoreHistoricTasks = useCallback(() => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

  const handleTaskClick = (task: ITask) => {
    // Implement task click logic here
  };

  const handleResolveTask = (task: ITask) => {
    setSelectedTask(task);
    setResolveDialogOpen(true);
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasMore) {
        loadMoreHistoricTasks();
      }
    }
  }, [hasMore, loadMoreHistoricTasks]);

  const navigate = useNavigate();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2
            }}
          >
            <Typography variant={isMobile ? 'h5' : 'h4'} component="h1">
              Resident Task Management
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: { display: 'none' }
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon icon="carbon:dashboard" />}
                size={isMobile ? 'small' : 'small'}
                onClick={() =>
                  navigate('/charts', {
                    state: { residentId }
                  })
                }
              >
                Charts
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setDialogOpen(true)}
                size={isMobile ? 'small' : 'small'}
              >
                Add Task
              </Button>
            </Box>
          </Box>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            variant={isMobile ? 'fullWidth' : 'standard'}
          >
            <Tab label="All" />
            <Tab label="Pending" />
            <Tab label="Historical" />
            <Tab label="Profile" />
          </Tabs>
        </Box>

        <Box
          ref={contentRef}
          sx={{ flexGrow: 1, overflowY: 'auto', p: 1 }}
          onScroll={tabValue === 2 ? handleScroll : undefined}
        >
          {tabValue === 3 ? (
            <ResidentProfileDialog residentId={resident._id} />
          ) : tabValue === 2 ? (
            <>
              <Box sx={{ mb: 2, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => newValue && setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => newValue && setEndDate(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </Box>
              <TaskGrid
                tasks={historicalTasks}
                isHistorical
                onTaskClick={() => {}}
              />
              {hasMore && (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                  <CircularProgress />
                </Box>
              )}
            </>
          ) : (
            <TaskGrid
              tasks={
                tasks?.filter(
                  (task) => tabValue === 0 || task.status === 'pending'
                ) || []
              }
              onTaskClick={handleResolveTask}
            />
          )}
        </Box>

        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Add Task</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="dense">
              <InputLabel>Task Type</InputLabel>
              <Select
                value={newTask.taskType}
                onChange={(e) =>
                  setNewTask({ ...newTask, taskType: e.target.value as string })
                }
              >
                {taskTypes.map((type) => (
                  <MenuItem key={type.type} value={type.type}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {type.icon}
                      <Typography sx={{ ml: 1 }}>{type.type}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Task Name"
              fullWidth
              value={newTask.taskName}
              onChange={(e) =>
                setNewTask({ ...newTask, taskName: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={2}
              value={newTask.description}
              onChange={(e) =>
                setNewTask({ ...newTask, description: e.target.value })
              }
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Frequency</InputLabel>
              <Select
                value={newTask.frequency.type}
                onChange={(e) =>
                  setNewTask({
                    ...newTask,
                    frequency: {
                      ...newTask.frequency,
                      type: e.target.value as 'daily' | 'weekly' | 'monthly'
                    }
                  })
                }
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>
            {newTask.frequency.type === 'daily' && (
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={useTimeInterval}
                      onChange={(e) => setUseTimeInterval(e.target.checked)}
                    />
                  }
                  label="Use Time Interval"
                />
                {useTimeInterval ? (
                  <>
                    <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                      <TimePicker
                        label="Start Time"
                        value={startTime}
                        onChange={(newValue) => setStartTime(newValue)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                      <TimePicker
                        label="End Time"
                        value={endTime}
                        onChange={(newValue) => setEndTime(newValue)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Box>
                    <TextField
                      margin="dense"
                      label="Interval (hours)"
                      type="number"
                      fullWidth
                      value={intervalHours}
                      onChange={(e) => setIntervalHours(Number(e.target.value))}
                      InputProps={{ inputProps: { min: 1, max: 24 } }}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ mt: 1 }}
                    >
                      This will create tasks every {intervalHours} hour(s)
                      between the start and end times.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle1">Task Times</Typography>
                    {selectedTimes.map((time, index) => (
                      <Chip
                        key={index}
                        label={time}
                        onDelete={() => {
                          const newTimes = [...selectedTimes];
                          newTimes.splice(index, 1);
                          setSelectedTimes(newTimes);
                        }}
                        sx={{ mr: 1, mb: 1 }}
                      />
                    ))}
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <TimePicker
                        label="Select Time"
                        value={tempSelectedTime}
                        onChange={handleTimeChange}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth margin="dense" />
                        )}
                      />
                      <Button
                        onClick={handleAddTime}
                        variant="contained"
                        sx={{ ml: 1, mb: 1 }}
                        disabled={!tempSelectedTime}
                      >
                        Add
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button
              onClick={handleAddTask}
              variant="contained"
              color="primary"
              disabled={
                (useTimeInterval && (!startTime || !endTime)) ||
                (!useTimeInterval && selectedTimes.length === 0)
              }
            >
              Add Task{useTimeInterval || selectedTimes.length > 1 ? 's' : ''}
            </Button>
          </DialogActions>
        </Dialog>

        {selectedTask && (
          <>
            {selectedTask.taskType === 'turn' && (
              <ResidentTurnDialog
                open={resolveDialogOpen}
                onClose={() => setResolveDialogOpen(false)}
                onResolve={handleCompleteTask}
              />
            )}
            {selectedTask.taskType === 'pad check' && (
              <PadCheckDialog
                open={resolveDialogOpen}
                onClose={() => setResolveDialogOpen(false)}
                onResolve={handleCompleteTask}
              />
            )}
            {(selectedTask.taskType === 'meal' ||
              selectedTask.taskType === 'fluid intake') && (
              <MealFluidDialog
                open={resolveDialogOpen}
                onClose={() => setResolveDialogOpen(false)}
                onResolve={handleCompleteTask}
              />
            )}
            {selectedTask.taskType === 'day check' && (
              <WakeAsleepCheckDialog
                open={resolveDialogOpen}
                onClose={() => setResolveDialogOpen(false)}
                onResolve={handleCompleteTask}
              />
            )}
            {selectedTask.taskType === 'night check' && (
              <WakeAsleepCheckDialog
                open={resolveDialogOpen}
                onClose={() => setResolveDialogOpen(false)}
                onResolve={handleCompleteTask}
              />
            )}
            {selectedTask.taskType === 'medication' && (
              <MedicationDialog
                open={resolveDialogOpen}
                onClose={() => setResolveDialogOpen(false)}
                onResolve={handleCompleteTask}
              />
            )}
            {selectedTask.taskType === 'personal care' && (
              <PersonalCareDialog
                open={resolveDialogOpen}
                onClose={() => setResolveDialogOpen(false)}
                onResolve={handleCompleteTask}
              />
            )}
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default TaskManagementComponent;
