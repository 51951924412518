import React, { useState, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Fab,
  Box,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  LinearProgress
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import AddResidentDialog from 'src/components/core/dialogs/add-residents';
import { useAppSelector } from 'src/redux/hook';
import TaskManagementComponent from '../home/views/task-management';
import { useGetResidentsQuery } from 'src/redux/@api/resident';

export interface ITaskSummary {
  upcoming: number;
  pending: number;
  overdue: number;
  total: number;
  urgency: 'white' | 'green' | 'amber' | 'red';
}

export interface IResident {
  _id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  roomNumber: string;
  admissionDate: Date;
  medicalConditions: string[];
  allergies: string[];
  group: string | null;
  emergencyContact: {
    name: string;
    relationship: string;
    phone: string;
  };
  tasks: string[];
  taskSummary: ITaskSummary;
  profilePictureUrl?: string;
  taskUrgency: 'white' | 'green' | 'amber' | 'red';
  carePlan: any;
}

const POLLING_INTERVAL = 10000; // 10 seconds

const ResidentPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const homeId = useAppSelector((state) => state.userState.currentOrganization._id);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState<IResident | null>(null);

  const {
    data: residents,
    isLoading,
    refetch
  } = useGetResidentsQuery(undefined, {
    pollingInterval: POLLING_INTERVAL
  });

  const getResidentStatus = useCallback((resident: IResident) => {
    const { taskUrgency, taskSummary } = resident;
    let color: string;
    let progress: number;

    switch (taskUrgency) {
      case 'red':
        color = theme.palette.error.main;
        progress = 100;
        break;
      case 'amber':
        color = theme.palette.warning.main;
        progress = 66;
        break;
      case 'green':
        color = theme.palette.success.main;
        progress = 33;
        break;
      default:
        color = theme.palette.grey[300];
        progress = 0;
    }

    let text = 'No tasks';
    if (taskSummary.overdue > 0) {
      text = `${taskSummary.overdue} overdue task${taskSummary.overdue > 1 ? 's' : ''}`;
    } else if (taskSummary.pending > 0) {
      text = `${taskSummary.pending} pending task${taskSummary.pending > 1 ? 's' : ''}`;
    } else if (taskSummary.upcoming > 0) {
      text = `${taskSummary.upcoming} upcoming task${taskSummary.upcoming > 1 ? 's' : ''}`;
    }

    return { color, progress, text };
  }, [theme]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
        <Typography variant="h4" component="h1">
          Residents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </Box>

      <Grid 
        container 
        spacing={2} 
        sx={{ 
          height: `calc(100vh - ${theme.spacing(20)})`, 
          overflowY: 'auto',
          [theme.breakpoints.down('sm')]: {
            height: 'auto',
            overflowY: 'visible',
          }
        }}
      >
        {residents?.map((resident: IResident) => {
          const status = getResidentStatus(resident);
          return (
            <Grid 
              item 
              xs={6}
              sm={4} 
              md={2} 
              key={resident._id} 
              sx={{ 
                height: isMobile ? '180px' : '200px',
                mb: isMobile ? 2 : 0
              }}
            >
              <Card
                onClick={() => setSelectedResident(resident)}
                sx={{
                  padding: 0,
                  position: 'relative',
                  cursor: 'pointer',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  },
                  overflow: 'hidden',
                  height: '100%',
                  width: '100%',
                }}
              >
                <CardContent
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '3px !important',
                    zIndex: 1,
                    height: '100%'
                  }}
                >
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <Avatar
                      src={resident.profilePictureUrl}
                      alt={`${resident.firstName} ${resident.lastName}`}
                      sx={{
                        width: '100%',
                        height: '70%',
                        margin: '0 auto',
                        borderRadius: '10px !important'
                      }}
                    />
                    <LinearProgress
                      variant="determinate"
                      value={status.progress}
                      sx={{
                        width: '100%',
                        height: 4,
                        borderRadius: 2,
                        my: 0.5,
                        backgroundColor: theme.palette.grey[300],
                        '& .MuiLinearProgress-bar': {
                          borderRadius: 2,
                          backgroundColor: status.color,
                        },
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '30%'
                      }}
                    >
                      <Typography 
                        variant="subtitle2" 
                        textAlign="center" 
                        sx={{ 
                          zIndex: 1000, 
                          lineHeight: 1,
                          fontSize: isMobile ? '0.7rem' : '0.8rem'
                        }}
                      >
                        {`${resident.firstName} ${resident.lastName}`}
                      </Typography>
                      <Typography 
                        sx={{ 
                          height: 'fit-content !important', 
                          fontSize: isMobile ? '0.6rem' : '0.7rem'
                        }}
                      >
                        {resident.roomNumber}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Fab
        color="primary"
        aria-label="add resident"
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2)
        }}
        onClick={() => setDialogOpen(true)}
      >
        <AddIcon />
      </Fab>

      <AddResidentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        homeId={homeId}
      />

      {selectedResident && (
        <Dialog
          open={!!selectedResident}
          onClose={() => setSelectedResident(null)}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              width: '80vw',
              margin: '0px !important',
              [theme.breakpoints.down('sm')]: {
                width: '96vw'
              }
            }
          }}
        >
          <DialogTitle>{`${selectedResident.firstName} ${selectedResident.lastName}'s Tasks`}</DialogTitle>
          <DialogContent>
            <TaskManagementComponent resident={selectedResident} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedResident(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default ResidentPage;