import React, { useRef, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Button,
  Tabs,
  Tab,
  List,
  ListItemButton,
  ListItemText,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  SlideProps,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { _setProfilePicture } from 'src/redux/reducers/users';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProfileTab from './views/profile';
import CareHomeOverviewTab from './views/overview';
import CareHomePreferences from './views/preference';
import AccountSettingsTab from './views/settings';
import { _setAvatar } from 'src/redux/reducers/user-slice';

const StyledRoot = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  backgroundColor: '#ffffff',
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - ${theme.header.height} - 20px)`,
    overflowY: 'auto',
    width: '95%',
    margin: 'auto',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
  }
}));

const StyledProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4)
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  marginRight: theme.spacing(2),
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4]
  },
  [theme.breakpoints.up('sm')]: {
    width: 120,
    height: 120
  }
}));

const HiddenInput = styled('input')({
  display: 'none'
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.colors.primary.main + '!important',
  paddingInline: theme.spacing(2)
}));

const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  backgroundColor: theme.colors.alpha.trueWhite[10]
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const Transition = React.forwardRef<HTMLDivElement, SlideProps>(
  (props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CareHomeSettings = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const userState = useAppSelector((state) => state.userState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      const formData = new FormData();

      const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
      const newFileName = `profile_picture_${
        userState.user._id
      }_${Date.now()}${fileExtension}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/${userState.user._id}/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({ message: 'Profile picture updated', color: 'success' })
          );
          console.log('Profile picture updated:', response.data);
          dispatch(_setAvatar(response.data?.data?.avatarUrl));
        } else {
          throw new Error(
            response.data.error || 'Failed to update profile picture'
          );
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        dispatch(showSnack({ message: error.message, color: 'error' }));
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleListItemClick = (index: number) => {
    setTabValue(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const avatarUrl = `${userState.user.avatarUrl}${
    userState.user.avatarUrl?.includes('?') ? '&' : '?'
  }cacheBust=${Date.now()}`;

  console.log('avatarUrl', avatarUrl);

  return (
    <StyledRoot>
      <StyledProfileHeader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isUploading ? (
            <CircularProgress size={80} />
          ) : (
            <StyledAvatar
              src={avatarUrl}
              alt={userState.user.firstName}
              onClick={handleAvatarClick}
              key={userState.user.avatarUrl}
            />
          )}
          <HiddenInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
          />

          <Box>
            <Typography variant={isMobile ? 'h6' : 'h4'}>
              {userState.user.firstName} {userState.user.lastName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {userState.user.email}
            </Typography>
          </Box>
        </Box>
        {!isMobile && (
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={handleEditToggle}
            size={isMobile ? 'small' : 'medium'}
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        )}
      </StyledProfileHeader>

      {isMobile ? (
        <StyledList>
          {[
            'Profile',
            'Care Home Overview',
            'Shift Settings',
            'Account Settings'
          ].map((text, index) => (
            <React.Fragment key={text}>
              <StyledListItem onClick={() => handleListItemClick(index)}>
                <StyledListItemText primary={text} />
              </StyledListItem>
              <Divider variant="middle" />
            </React.Fragment>
          ))}
        </StyledList>
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="care home settings tabs"
          >
            <Tab label="Profile" />
            <Tab label="Care Home Overview" />
            <Tab label="Shift Settings" />
            <Tab label="Account Settings" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            <ProfileTab isEditing={isEditing} onEditToggle={handleEditToggle} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <CareHomeOverviewTab />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <CareHomePreferences />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <AccountSettingsTab />
          </TabPanel>
        </>
      )}

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <StyledAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {['Profile', 'Care Home Overview', 'Shift Settings'][tabValue]}
            </Typography>
          </Toolbar>
        </StyledAppBar>
        <Box sx={{ p: 2 }}>
          {tabValue === 0 && (
            <ProfileTab isEditing={isEditing} onEditToggle={handleEditToggle} />
          )}
          {tabValue === 1 && <CareHomeOverviewTab />}
          {tabValue === 2 && <CareHomePreferences />}
          {tabValue === 3 && <AccountSettingsTab />}
        </Box>
      </Dialog>
    </StyledRoot>
  );
};

export default CareHomeSettings;
