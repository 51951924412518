// userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type {
  IAddress,
  IOrganization,
  IOrganizationRole,
  IUser
} from 'src/interfaces/heirarchy';
import type { IJoinRequest } from 'src/interfaces/join-requests';
import { authApi } from '../@api/auth';

export interface IUserState {
  user: IUser | null;
  notificationEnabled: boolean;
  loading: boolean;
  error: string | null;
  currentOrganization: IOrganization | null;
  organizationRoles: IOrganizationRole[];
  pendingJoinRequest: IJoinRequest | null;
  staffType: string | null;
}

const initialState: IUserState = {
  user: null,
  notificationEnabled: false,
  loading: true,
  error: null,
  currentOrganization: null,
  pendingJoinRequest: null,
  organizationRoles: [],
  staffType: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    _setNotificationEnabled: (state, action: PayloadAction<boolean>) => {
      state.notificationEnabled = action.payload;
    },
    _setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    _setAvatar: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.avatarUrl = action.payload;
      }
    },
    _updateUserProfile: (state, action: PayloadAction<Partial<IUser>>) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
      }
    },
    _setCurrentOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.currentOrganization = action.payload;
    },
    _setPendingJoinRequest: (state, action: PayloadAction<IJoinRequest>) => {
      state.pendingJoinRequest = action.payload;
    },
    _updateUserAddress: (state, action: PayloadAction<IAddress>) => {
      if (state.user) {
        state.user.address = action.payload;
      }
    },
    _setCurrentOrganizationRoles: (
      state,
      action: PayloadAction<IOrganizationRole[]>
    ) => {
      state.organizationRoles = action.payload;
    },
    _clearUser: (state) => {
      state.user = null;
      state.error = null;
      state.loading = false;
      state.currentOrganization = null;
      state.pendingJoinRequest = null;
    },
    _setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    _setAvatarUrl: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.avatarUrl = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getUser.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        state.user = action.payload.user;
        state.notificationEnabled = action.payload.notificationEnabled;
        state.currentOrganization = action.payload.currentOrganization;
        state.organizationRoles = action.payload.organizationRoles;
        state.pendingJoinRequest = action.payload.pendingJoinRequest;
        state.loading = false;
        state.error = null;
        state.staffType = action.payload.staffType;
      }
    );
  }
});

export const {
  _setNotificationEnabled,
  _setUser,
  _updateUserProfile,
  _updateUserAddress,
  _setCurrentOrganization,
  _setCurrentOrganizationRoles,
  _setPendingJoinRequest,
  _clearUser,
  _setError,
  _setAvatar
} = userSlice.actions;
export default userSlice.reducer;
