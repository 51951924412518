import React from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Paper,
  Divider,
  styled
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { EditIcon, Save } from 'lucide-react';
import { Theme, useTheme } from '@mui/material/styles';
import { useUpdateOrganizationMutation } from 'src/redux/@api/organisation';

interface ProfileTabProps {
  isEditing: boolean;
  onEditToggle: () => void;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  background: theme.palette.background.paper
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 3)
}));

const ProfileTab: React.FC<ProfileTabProps> = ({ isEditing, onEditToggle }) => {
  const userState = useAppSelector((state) => state.userState);
  const dispatch = useDispatch();
  const theme = useTheme();

  const [updateOrg] = useUpdateOrganizationMutation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      careHomeName: userState.currentOrganization?.name || '',
      careHomeType: userState.currentOrganization?.type || '',
      street: userState.currentOrganization.address?.street || '',
      city: userState.currentOrganization.address?.city || '',
      state: userState.currentOrganization.address?.state || '',
      zipCode: userState.currentOrganization.address?.zipCode || '',
      country: userState.currentOrganization.address?.country || '',
      phoneNumber: userState.user.phone || '',
      website: ''
    }
  });

  const onSubmit = async (data) => {
    try {
      const response = await updateOrg({
        name: data.careHomeName,
        type: data.careHomeType,
        address: {
          street: data.street,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          country: data.country
        },
        phone: data.phoneNumber,
        website: data.website
      }).unwrap();
      console.log(response);
      onEditToggle();
      dispatch(
        showSnack({ message: 'Profile updated successfully', color: 'success' })
      );
    } catch (error) {}
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <StyledPaper elevation={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <StyledTypography variant="h5">Care Home Profile</StyledTypography>
          <StyledButton
            variant="outlined"
            type={isEditing ? 'button' : 'submit'}
            startIcon={isEditing ? <Save /> : <EditIcon />}
            onClick={onEditToggle}
            size={isMobile ? 'small' : 'medium'}
            color={isEditing ? 'primary' : 'secondary'}
          >
            {isEditing ? 'Save' : 'Edit'}
          </StyledButton>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Controller
              name="careHomeName"
              control={control}
              rules={{ required: 'Care Home Name is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="Care Home Name"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="careHomeType"
              control={control}
              rules={{ required: 'Care Home Type is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="Care Home Type"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="street"
              control={control}
              rules={{ required: 'Street is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="Street"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="city"
              control={control}
              rules={{ required: 'City is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="City"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="state"
              control={control}
              rules={{ required: 'State is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="State"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="zipCode"
              control={control}
              rules={{ required: 'Zip Code is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="Zip Code"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="country"
              control={control}
              rules={{ required: 'Country is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="Country"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: 'Phone Number is required' }}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="website"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  size="small"
                  {...field}
                  fullWidth
                  label="Website"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.message}
                  disabled={!isEditing}
                  margin="normal"
                />
              )}
            />
          </Grid>
        </Grid>

        {isEditing && (
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <StyledButton type="submit" variant="contained" color="primary">
              Save Changes
            </StyledButton>
          </Box>
        )}
      </form>
    </StyledPaper>
  );
};

export default ProfileTab;
