import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled
} from '@mui/material';
import { useAppSelector } from 'src/redux/hook';
import dayjs from 'dayjs';

const StyledInfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4]
  },
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
    background: 'transparent'
  }
}));

const CareHomeOverviewTab = () => {
  const [shiftPeriod, setShiftPeriod] = useState('month');
  const shiftState = useAppSelector((state) => state.shifts.shifts);

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        Care Home Overview
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="subtitle1" gutterBottom>
              Number of Residents
            </Typography>
            <Typography variant="h4">{0}</Typography>
          </StyledInfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="subtitle1" gutterBottom>
              Staff Members
            </Typography>
            <Typography variant="h4">{0}</Typography>
          </StyledInfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="subtitle1" gutterBottom>
              Shifts Scheduled
            </Typography>
            <FormControl fullWidth size="small" sx={{ mb: 1 }}>
              <InputLabel>Period</InputLabel>
              <Select
                value={shiftPeriod}
                onChange={(e) => setShiftPeriod(e.target.value)}
                label="Period"
              >
                <MenuItem value="month">This Month</MenuItem>
                <MenuItem value="year">This Year</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h4">
              {shiftPeriod === 'month'
                ? shiftState.filter(
                    (shift) => dayjs(shift.date).month() === dayjs().month()
                  ).length || 0
                : shiftState.filter(
                    (shift) => dayjs(shift.date).year() === dayjs().year()
                  ).length || 0}
            </Typography>
          </StyledInfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="subtitle1" gutterBottom>
              Bed Capacity
            </Typography>
            <Typography variant="h4">{0}</Typography>
          </StyledInfoCard>
        </Grid>
      </Grid>
    </>
  );
};

export default CareHomeOverviewTab;
