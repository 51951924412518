import React, { useRef, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  useTheme,
  useMediaQuery,
  Tabs,
  Tab,
  SlideProps,
  ListItemButton,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import { _setProfilePicture } from 'src/redux/reducers/users';
import axios from 'axios';
import { apiHostname } from 'src/api/api';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm, Controller } from 'react-hook-form';
import Preferences from './view/preference';
import OrganizationOverviewTab from './view/organisation-setups';
import AccountSettingsTab from './view/settings';
import { Divide } from 'lucide-react';
import { ArrowBackSharp } from '@mui/icons-material';

const StyledRoot = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  backgroundColor: '#ffffff',
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - ${theme.header.height} - 20px)`,
    overflowY: 'auto',
    width: '95%',
    margin: 'auto',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
  }
}));

const StyledProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(4)
  }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 80,
  height: 80,
  marginRight: theme.spacing(2),
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4]
  },
  [theme.breakpoints.up('sm')]: {
    width: 120,
    height: 120
  }
}));

const HiddenInput = styled('input')({
  display: 'none'
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.colors.primary.main + '!important',
  paddingInline: theme.spacing(2)
}));

const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  backgroundColor: theme.colors.alpha.trueWhite[10]
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const Transition = React.forwardRef<HTMLDivElement, SlideProps>(
  (props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AgencySettings = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const userState = useAppSelector((state) => state.userState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef(null);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      organizationName: userState.currentOrganization?.name || '',
      businessType: userState.currentOrganization?.type || '',
      street: userState.user.address?.street || '',
      city: userState.user.address?.city || '',
      state: userState.user.address?.state || '',
      zipCode: userState.user.address?.zipCode || '',
      country: userState.user.address?.country || '',
      phoneNumber: userState.user.phone || '',
      website: ''
    }
  });

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      const formData = new FormData();

      const fileExtension = file.name.substr(file.name.lastIndexOf('.'));
      const newFileName = `profile_picture_${
        userState.user._id
      }_${Date.now()}${fileExtension}`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/${userState.user._id}/upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (response.data.success) {
          dispatch(
            showSnack({ message: 'Profile picture updated', color: 'success' })
          );
          dispatch(_setProfilePicture(response.data?.data?.avatarUrl));
        } else {
          throw new Error(
            response.data.error || 'Failed to update profile picture'
          );
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        dispatch(showSnack({ message: error.message, color: 'error' }));
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const onSubmit = (data) => {
    console.log(data);
    setIsEditing(false);
    dispatch(
      showSnack({ message: 'Profile updated successfully', color: 'success' })
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleListItemClick = (index: number) => {
    setTabValue(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const renderProfileForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isMobile && (
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={handleEditToggle}
              size={isMobile ? 'small' : 'medium'}
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="organizationName"
            control={control}
            rules={{ required: 'Organization Name is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Organization Name"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="businessType"
            control={control}
            rules={{ required: 'Business Type is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Business Type"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="street"
            control={control}
            rules={{ required: 'Street is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Street"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="city"
            control={control}
            rules={{ required: 'City is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="City"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="state"
            control={control}
            rules={{ required: 'State is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="State"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="zipCode"
            control={control}
            rules={{ required: 'Zip Code is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Zip Code"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="country"
            control={control}
            rules={{ required: 'Country is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Country"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{ required: 'Phone Number is required' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Phone Number"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="website"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label="Website"
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                disabled={!isEditing}
                margin="normal"
              />
            )}
          />
        </Grid>
      </Grid>
      {isEditing && (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" variant="contained" color="primary">
            Save Changes
          </Button>
        </Box>
      )}
    </form>
  );

  return (
    <StyledRoot>
      <StyledProfileHeader>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isUploading ? (
            <CircularProgress size={80} />
          ) : (
            <StyledAvatar
              src={userState.user.avatarUrl || '/profile-image.jpg'}
              alt={userState.user.firstName}
              onClick={handleAvatarClick}
            />
          )}
          <HiddenInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/*"
          />
          <Box>
            <Typography variant={isMobile ? 'h6' : 'h4'}>
              {userState.user.firstName} {userState.user.lastName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {userState.user.email}
            </Typography>
          </Box>
        </Box>
        {!isMobile && (
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={handleEditToggle}
            size={isMobile ? 'small' : 'medium'}
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        )}
      </StyledProfileHeader>

      {isMobile ? (
        <StyledList>
          {[
            'Profile',
            'Organization Overview',
            'Shift Settings',
            'Account Settings'
          ].map((text, index) => (
            <>
              <StyledListItem
                key={text}
                onClick={() => handleListItemClick(index)}
              >
                <StyledListItemText primary={text} />
              </StyledListItem>
              <Divider variant="middle" />
            </>
          ))}
        </StyledList>
      ) : (
        <>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="agency settings tabs"
          >
            <Tab label="Profile" />
            <Tab label="Organization Overview" />
            <Tab label="Shift Settings" />
            <Tab label="Account Settings" />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            {renderProfileForm()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <OrganizationOverviewTab />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Preferences />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <AccountSettingsTab />
          </TabPanel>
        </>
      )}

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <StyledAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <ArrowBackSharp />
            </IconButton>
            <Typography sx={{ ml: 0, flex: 1 }} variant="h6" component="div">
              {
                [
                  'Profile',
                  'Organization Overview',
                  'Shift Settings',
                  'Account Settings'
                ][tabValue]
              }
            </Typography>
          </Toolbar>
        </StyledAppBar>
        <Box sx={{ p: 2 }}>
          {tabValue === 0 && renderProfileForm()}
          {tabValue === 1 && <OrganizationOverviewTab />}
          {tabValue === 2 && <Preferences />}
          {tabValue === 3 && <AccountSettingsTab />}
        </Box>
      </Dialog>
    </StyledRoot>
  );
};

export default AgencySettings;
