import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
  Chip,
  InputAdornment,
  useTheme,
  alpha,
  ListItemButton,
  Divider
} from '@mui/material';
import { Link, Close, Clear, Search } from '@mui/icons-material';

import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useSearchOrganizationsQuery,
  useSendLinkOrganizationMutation
} from 'src/redux/@api/organisation';
import { Icon } from '@iconify/react';

interface SearchOrganization {
  _id: string;
  name: string;
  type: 'agency' | 'home';
}

interface SearchOrganizationsModalProps {
  open: boolean;
  onClose: () => void;
}

const SearchOrganizationsModal: React.FC<SearchOrganizationsModalProps> = ({
  open,
  onClose
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchOrganization[]>([]);
  const { user, currentOrganization } = useAppSelector(
    (state) => state.userState
  );

  const dispatch = useDispatch();

  const handleLinkOrganization = async (orgId: string) => {
    try {
      const response = await sendInvitation({
        fromOrganizationId: currentOrganization._id,
        toOrganizationId: orgId
      }).unwrap();
      console.log('Invitation sent:', response);
      dispatch(showSnack({ message: 'Invitation sent', color: 'success' }));
      onClose();
    } catch (error) {
      dispatch(showSnack({ message: error.message, color: 'error' }));
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm === '') {
      setSearchResults([]);
    }
  };

  const {
    data: searchResultsData,
    isLoading,
    error
  } = useSearchOrganizationsQuery(
    {
      term: searchTerm,
      exludedType: user.role
    },
    {
      skip: searchTerm.length < 3
    }
  );

  const [sendInvitation, { isLoading: isSending }] =
    useSendLinkOrganizationMutation();

  useEffect(() => {
    if (searchResultsData?.organizations) {
      setSearchResults(searchResultsData.organizations);
    } else {
      setSearchResults([]);
    }
  }, [searchResultsData]);

  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: theme.shadows[10]
        }
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          py: 2
        }}
      >
        <Typography variant="h6" component="div">
          Search Organizations
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ color: theme.palette.primary.contrastText }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3, mt: 2 }}>
        <Box sx={{ display: 'flex', mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search organizations..."
            value={searchTerm}
            onChange={handleSearchTermChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                      size="small"
                    >
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              sx: {
                borderRadius: 2,
                '&:hover': {
                  boxShadow: theme.shadows[2]
                }
              }
            }}
          />
        </Box>
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <Typography>Loading...</Typography>
          </Box>
        )}
        {error && (
          <Typography color="error" sx={{ my: 2 }}>
            Error: {error.toString()}
          </Typography>
        )}
        <List sx={{ maxHeight: 500, overflowY: 'auto', padding: 0 }}>
          {searchResults.map((org) => (
            <div key={org._id}>
              <ListItemButton
                sx={{
                  display: 'flex',
                  borderRadius: 1,
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.1)
                  },
                  transition: 'background-color 0.3s'
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">{org.name}</Typography>
                  }
                  secondary={
                    <Chip
                      label={org.type}
                      size="small"
                      sx={{
                        bgcolor:
                          org.type === 'agency'
                            ? theme.palette.info.main
                            : theme.palette.success.main,
                        color: '#fff',
                        fontWeight: 'bold',
                        mt: 0.5
                      }}
                    />
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="link"
                    onClick={() => handleLinkOrganization(org._id)}
                    disabled={isSending}
                    sx={{
                      color: theme.palette.primary.main,
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.1)
                      }
                    }}
                  >
                    <Icon icon="mingcute:send-line" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
              <Divider />
            </div>
          ))}
        </List>
        {!isLoading && searchResults.length === 0 && searchTerm.length >= 3 && (
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 2 }}
          >
            No results found
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{ px: 3, py: 2, borderTop: `1px solid ${theme.palette.divider}` }}
      >
        <Button onClick={onClose} variant="outlined" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchOrganizationsModal;
