import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import CareStaffList from './care-staffs';
import AdminStaffList from './admin-staffs';
import OtherStaffList from './other-staffs';

// Mock data for demonstration
const mockStaff = [
  {
    id: 1,
    name: 'John Doe',
    role: 'carer',
    email: 'john@example.com',
    phone: '123-456-7890',
    specialization: 'Elderly Care'
  },
  {
    id: 2,
    name: 'Jane Smith',
    role: 'nurse',
    email: 'jane@example.com',
    phone: '098-765-4321',
    specialization: 'Pediatrics'
  },
  {
    id: 3,
    name: 'Mike Johnson',
    role: 'admin',
    email: 'mike@example.com',
    phone: '111-222-3333',
    department: 'Human Resources'
  },
  {
    id: 4,
    name: 'Sarah Brown',
    role: 'hr_manager',
    email: 'sarah@example.com',
    phone: '444-555-6666',
    department: 'Human Resources'
  },
  {
    id: 5,
    name: 'Tom Wilson',
    role: 'staff',
    email: 'tom@example.com',
    phone: '777-888-9999',
    notes: 'Part-time employee'
  }
];

const StaffTabs: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [careStaff, setCareStaff] = useState([]);
  const [adminStaff, setAdminStaff] = useState([]);
  const [otherStaff, setOtherStaff] = useState([]);

  useEffect(() => {}, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const theme = useTheme();

  return (
    <Box
      sx={{ width: '100%', height: `calc(100vh - ${theme.header.height})` }}
      padding={theme.spacing(2)}
    >
      <Box sx={{ mb: 3, height: '60px' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="staff tabs"
        >
          <Tab label="Care Staff" />
          <Tab label="Admin Staff" />
          <Tab label="Other Staff" />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto'
          }}
        >
          <CareStaffList />
        </Box>
      )}
      {tabValue === 1 && (
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto'
          }}
        >
          <AdminStaffList />
        </Box>
      )}
      {tabValue === 2 && (
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto'
          }}
        >
          <OtherStaffList staffList={[]} />
        </Box>
      )}
    </Box>
  );
};

export default StaffTabs;
