import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Switch,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  ListItemButton
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { styled } from '@mui/system';
import {
  useGetFieldVisibilityQuery,
  useUpdateFieldVisibilityMutation,
  useInitializeFieldVisibilityMutation
} from 'src/redux/@api/visibility';
import { IFieldVisibility } from 'src/interfaces/visiblity';
import { useAppSelector } from 'src/redux/hook';
import FieldVisibilityCheckboxes from './visibility-checkboxes';

const StyledInfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.shape.borderRadius,
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)'
  }
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.action.selected,
  margin: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const VisibilityGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const VisibilityItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.action.selected,
  marginInline: '15px',
  marginBlock: '5px',
  padding: '5px 10px !important',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const OrganizationOverviewTab = () => {
  const userState = useAppSelector((state) => state.userState);
  const shiftState = useAppSelector((state) => state.shifts.shifts);
  const [shiftPeriod, setShiftPeriod] = useState('month');
  const [visibilityState, setVisibilityState] =
    useState<IFieldVisibility | null>(null);
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const {
    data: fieldVisibility,
    isLoading,
    isError,
    refetch
  } = useGetFieldVisibilityQuery(undefined);
  const [updateFieldVisibility, { isLoading: isUpdating }] =
    useUpdateFieldVisibilityMutation();
  const [initializeFieldVisibility, { isLoading: isInitializing }] =
    useInitializeFieldVisibilityMutation();

  useEffect(() => {
    if (fieldVisibility) {
      setVisibilityState(fieldVisibility.fields);
    }
  }, [fieldVisibility]);

  const handleVisibilityChange =
    (path: string[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setVisibilityState((prev) => {
        if (!prev) return null;
        const updateNestedObject = (
          obj: any,
          keys: string[],
          value: boolean
        ): any => {
          if (keys.length === 1) {
            return { ...obj, [keys[0]]: value };
          }
          const [currentKey, ...remainingKeys] = keys;
          return {
            ...obj,
            [currentKey]: updateNestedObject(
              obj[currentKey] || {},
              remainingKeys,
              value
            )
          };
        };
        return updateNestedObject(prev, path, event.target.checked);
      });
    };

  const handleSaveVisibility = async (data: any) => {
    try {
      await updateFieldVisibility(data).unwrap();
      setSnackbar({
        open: true,
        message: 'Visibility settings updated successfully',
        severity: 'success'
      });
      refetch();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update visibility settings',
        severity: 'error'
      });
    }
  };

  const handleInitializeVisibility = async () => {
    try {
      await initializeFieldVisibility(undefined).unwrap();
      setSnackbar({
        open: true,
        message: 'Visibility settings initialized successfully',
        severity: 'success'
      });
      refetch();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to initialize visibility settings',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const renderVisibilitySettings = (data: any, path: string[] = []) => {
    return Object.entries(data).map(([key, value]) => {
      const newPath = [...path, key];
      const sectionKey = newPath.join('.');

      if (key === 'userId') return null;

      if (typeof value === 'object' && value !== null) {
        return (
          <Grid md={6} sm={6} key={sectionKey}>
            <StyledListItem onClick={() => toggleSection(sectionKey)}>
              <ListItemText primary={key} />
              {openSections[sectionKey] ? <ExpandLess /> : <ExpandMore />}
            </StyledListItem>
            <Collapse
              in={openSections[sectionKey]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <Box sx={{ pl: 2 }}>
                  <VisibilityGrid container spacing={2}>
                    {renderVisibilitySettings(value, newPath)}
                  </VisibilityGrid>
                </Box>
              </List>
            </Collapse>
          </Grid>
        );
      } else if (typeof value === 'boolean') {
        return (
          <VisibilityItem item xs={12} key={sectionKey}>
            <Typography variant="body2">{key}</Typography>
            <Switch
              checked={value}
              onChange={handleVisibilityChange(newPath)}
            />
          </VisibilityItem>
        );
      }
      return null;
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (
      <Typography color="error">Error loading field visibility data</Typography>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Organization Overview
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="h6" gutterBottom>
              Number of Staff
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {0}
            </Typography>
          </StyledInfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="h6" gutterBottom>
              {userState.currentOrganization.type === 'home'
                ? 'Homes Linked'
                : 'Agencies Linked'}
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {userState.currentOrganization.type === 'home' ? 0 : 0}
            </Typography>
          </StyledInfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="h6" gutterBottom>
              Shifts Issued
            </Typography>
            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
              <InputLabel>Period</InputLabel>
              <Select
                value={shiftPeriod}
                onChange={(e) => setShiftPeriod(e.target.value as string)}
                label="Period"
              >
                <MenuItem value="month">This Month</MenuItem>
                <MenuItem value="year">This Year</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {shiftPeriod === 'month'
                ? shiftState.filter(
                    (shift) => dayjs(shift.date).month() === dayjs().month()
                  ).length || 0
                : shiftState.filter(
                    (shift) => dayjs(shift.date).year() === dayjs().year()
                  ).length || 0}
            </Typography>
          </StyledInfoCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledInfoCard>
            <Typography variant="h6" gutterBottom>
              Active Projects
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              0
            </Typography>
          </StyledInfoCard>
        </Grid>
      </Grid>

      <Typography
        variant="h4"
        gutterBottom
        sx={{ mt: 6, mb: 3, fontWeight: 'bold' }}
      >
        Update staff application visibility
      </Typography>
      <Paper sx={{ mt: 2, borderRadius: 2 }}>
        {visibilityState ? (
          <>
            <FieldVisibilityCheckboxes
              initialData={visibilityState}
              onSave={(data) => {
                handleSaveVisibility(data);
              }}
            />
          </>
        ) : (
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInitializeVisibility}
              disabled={isInitializing}
              sx={{ minWidth: 240 }}
            >
              {isInitializing ? (
                <CircularProgress size={24} />
              ) : (
                'Initialize Visibility Settings'
              )}
            </Button>
          </Box>
        )}
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrganizationOverviewTab;
