import React from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { ApexOptions } from 'apexcharts';

interface PadCheckAnalysisProps {
  padCheckAnalysis: {
    stoolTypeAnalysis: Record<string, number>;
    clothingConditionAnalysis: Record<string, number>;
    healthInsights: string[];
  };
}

const PadCheckAnalysis: React.FC<PadCheckAnalysisProps> = ({
  padCheckAnalysis
}) => {
  const theme = useTheme();

  const stoolTypeData = Object.entries(
    padCheckAnalysis?.stoolTypeAnalysis || {}
  ).map(([type, count]) => ({
    x: `Type ${type}`,
    y: count
  }));

  const clothingConditionData = Object.entries(
    padCheckAnalysis?.clothingConditionAnalysis || {}
  ).map(([condition, count]) => ({
    x: condition,
    y: count
  }));

  const pieChartOptions: ApexOptions = {
    chart: {
      type: 'donut'
    },
    labels: stoolTypeData.map((item) => item.x),
    colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
    legend: {
      position: 'bottom',
      fontFamily: theme.typography.fontFamily
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '22px',
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.text.primary
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: theme.typography.fontFamily,
              color: theme.palette.text.secondary
            },
            total: {
              show: true,
              label: 'Total',
              color: theme.palette.text.primary
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  const barChartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        barHeight: '70%',
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: clothingConditionData.map((item) => item.x),
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: theme.typography.fontFamily
        }
      }
    },
    colors: [
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#546E7A',
      '#26a69a'
    ],
    legend: {
      show: false
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    tooltip: {
      theme: theme.palette.mode
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Stool Type Distribution
            </Typography>
            <ReactApexChart
              options={pieChartOptions}
              series={stoolTypeData.map((item) => item.y)}
              type="donut"
              height={350}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card elevation={3}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Clothing Condition Analysis
            </Typography>
            <ReactApexChart
              options={barChartOptions}
              series={[{ data: clothingConditionData.map((item) => item.y) }]}
              type="bar"
              height={350}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={3}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontWeight: 'bold' }}
            >
              Health Insights
            </Typography>
            <List>
              {padCheckAnalysis?.healthInsights.map((insight, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <InfoOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={insight}
                    primaryTypographyProps={{ style: { fontWeight: 500 } }}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PadCheckAnalysis;
