import React, { useState, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Fab,
  Box,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  LinearProgress,
  Badge
} from '@mui/material';
import { Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import TaskManagementComponent from './views/task-management';
import AddResidentDialog from 'src/components/core/dialogs/add-residents';
import { useAppSelector } from 'src/redux/hook';
import { useGetResidentsQuery } from 'src/redux/@api/resident';

export interface ITaskSummary {
  upcoming: number;
  pending: number;
  overdue: number;
  total: number;
  urgency: 'white' | 'green' | 'amber' | 'red';
}

export interface IResident {
  _id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  roomNumber: string;
  admissionDate: Date;
  medicalConditions: string[];
  allergies: string[];
  group: string | null;
  emergencyContact: {
    name: string;
    relationship: string;
    phone: string;
  };
  tasks: string[];
  taskSummary: ITaskSummary;
  profilePictureUrl?: string;
  carePlan: any;
}

const POLLING_INTERVAL = 10000; // 10 seconds

const ResidentPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const homeId = useAppSelector(
    (state) => state.userState.currentOrganization._id
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedResident, setSelectedResident] = useState<IResident | null>(
    null
  );

  const {
    data: residents,
    isLoading,
    refetch
  } = useGetResidentsQuery(undefined, {
    pollingInterval: POLLING_INTERVAL
  });

  const getTaskStatus = useCallback(
    (taskSummary: ITaskSummary) => {
      const { urgency, overdue, pending, upcoming, total } = taskSummary;
      let color: string;
      let progress: number;

      switch (urgency) {
        case 'red':
          color = theme.palette.error.main;
          progress = 100;
          break;
        case 'amber':
          color = theme.palette.warning.main;
          progress = 66;
          break;
        case 'green':
          color = theme.palette.success.main;
          progress = 33;
          break;
        default:
          color = theme.palette.grey[300];
          progress = 0;
      }

      return { color, progress };
    },
    [theme]
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        height: `calc(100vh - ${theme.header.height})`
      }}
      maxWidth="xl"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
        sx={{
          height: '60px'
        }}
      >
        <Typography variant="h4" component="h1">
          Residents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </Box>

      <Grid
        container
        spacing={1}
        sx={{
          flex: 1,
          overflowY: 'auto',
          [theme.breakpoints.down('sm')]: {
            height: 'auto',
            overflowY: 'visible'
          }
        }}
      >
        {residents?.map((resident: IResident) => {
          const status = getTaskStatus(resident.taskSummary);
          return (
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
              key={resident._id}
              sx={{
                height: isMobile ? '130px' : '200px',
                mb: isMobile ? 2 : 0
              }}
            >
              <Card
                onClick={() => setSelectedResident(resident)}
                sx={{
                  padding: 0,
                  position: 'relative',
                  zIndex: 100 + '!important',
                  cursor: 'pointer',
                  transition: 'transform 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  },
                  overflow: 'visible',
                  height: '100%',
                  width: '100%'
                }}
              >
                <Badge
                  badgeContent={resident.roomNumber}
                  color="primary"
                  sx={{
                    position: 'absolute',
                    zIndex: 200,
                    top: 8, // Moved up slightly
                    right: 10, // Moved right slightly to stick out
                    '& .MuiBadge-badge': {
                      fontSize: isMobile ? '0.6rem' : '0.7rem',
                      height: 'auto',

                      padding: '4px 6px',
                      minWidth: '15px',
                      borderRadius: '12px', // Increased border radius for a pill-like shape
                      boxShadow: '0px 2px 4px rgba(0,0,0,0.2)' // Added shadow for depth
                    }
                  }}
                />
                <CardContent
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '3px !important',
                    zIndex: 1,
                    height: '100%'
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 0
                    }}
                  >
                    <Avatar
                      src={resident.profilePictureUrl}
                      alt={`${resident.firstName} ${resident.lastName}`}
                      sx={{
                        width: '100%',
                        height: '60%',
                        margin: '0 auto',
                        borderRadius: '10px !important',
                        objectFit: 'contain'
                      }}
                    />
                    <LinearProgress
                      variant="determinate"
                      value={status.progress}
                      sx={{
                        width: '100%',
                        height: 4,
                        borderRadius: 2,
                        my: 0.5,
                        backgroundColor: theme.palette.grey[300],
                        '& .MuiLinearProgress-bar': {
                          borderRadius: 2,
                          backgroundColor: status.color
                        }
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '40%'
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        textAlign="center"
                        sx={{
                          zIndex: 1000,
                          lineHeight: 1.2,
                          fontSize: isMobile ? '0.7rem' : '0.8rem',
                          height: '2.4em', // Fixed height for 2 lines
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          width: '100%', // Ensure full width
                          wordBreak: 'break-word' // Allow breaking long words
                        }}
                      >
                        {`${resident.firstName} ${resident.lastName}`}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <Fab
        color="primary"
        aria-label="add resident"
        sx={{
          position: 'fixed',
          bottom: theme.spacing(2),
          right: theme.spacing(2)
        }}
        onClick={() => setDialogOpen(true)}
      >
        <AddIcon />
      </Fab>

      <AddResidentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        homeId={homeId}
      />

      {selectedResident && (
        <Dialog
          open={selectedResident !== null}
          onClose={() => setSelectedResident(null)}
          // sx={{
          //   '& .MuiDialog-root': {
          //     padding: 0,
          //   }
          // }}
          fullWidth
          maxWidth={false}
          PaperProps={{
            sx: {
              m: 0,
              padding: 0,
              width: '100%',
              minHeight: '80%',
              maxHeight: '80%',
              [theme.breakpoints.down('sm')]: {
                width: '96vw',
                maxWidth: 'none'
              },
              [theme.breakpoints.up('sm')]: {
                width: '80%',
                maxWidth: 'none'
              }
            }
          }}
        >
          <DialogTitle>{`${selectedResident.firstName} ${selectedResident.lastName}'s Tasks`}</DialogTitle>
          <DialogContent
            sx={{
              px: 0,
              py: 1
            }}
          >
            <TaskManagementComponent resident={selectedResident} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedResident(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default ResidentPage;
