// interfaces.ts

export interface IAddress {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface IUser {
  avatarUrl?: string;
  _id: string;
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  role: 'admin' | 'staff' | 'carer' | 'nurse';
  phone: string;
  countryCode: string;
  address: IAddress;
  organizationRoles: any[]; // Array of OrganizationRole IDs
  emailVerified: boolean;
}

export interface IOrganization {
  _id: string;
  name: string;
  type: 'agency' | 'home';
  address: IAddress;
  phone: string;
  email: string;
  owner: string; // User ID
  parentCompany?: string; // ParentCompany ID
  staff: string[]; // Array of User IDs
  linkedOrganizations: string[]; // Array of Organization IDs
}

export interface IOrganizationRole {
  _id: string;
  user: string; // User ID
  organization: string; // Organization ID
  role: 'owner' | 'admin' | 'staff' | 'carer' | 'nurse';
  permissions: string[];
}

export interface IParentCompany {
  _id: string;
  name: string;
  address: IAddress;
  phone: string;
  email: string;
  organizations: string[]; // Array of Organization IDs
}

// Request body interfaces

export interface IRegisterUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: 'admin' | 'staff' | 'carer' | 'nurse';
  phone: string;
  address: IAddress;
}

export interface ICreateOrganizationRequest {
  name: string;
  type: 'agency' | 'home';
  address: IAddress;
  phone: string;
  email: string;
  ownerId: string;
}

export interface IAddUserToOrganizationRequest {
  userId: string;
  organizationId: string;
  role: 'admin' | 'staff' | 'carer' | 'nurse';
}

export interface IUpdateUserRoleRequest {
  userId: string;
  organizationId: string;
  newRole: 'admin' | 'staff' | 'carer' | 'nurse';
}

export interface ILinkOrganizationsRequest {
  organizationId1: string;
  organizationId2: string;
}

export interface ICreateParentCompanyRequest {
  name: string;
  address: IAddress;
  phone: string;
  email: string;
}

// Response interfaces

export interface IAuthResponse {
  token: string;
  user: IUser;
}

export interface IOrganizationResponse {
  organization: IOrganization;
}

export interface IOrganizationRoleResponse {
  organizationRole: IOrganizationRole;
}

export interface IParentCompanyResponse {
  parentCompany: IParentCompany;
}

// Utility types

export type OrganizationType = 'agency' | 'home';
export type UserRole = 'admin' | 'staff' | 'carer' | 'nurse';
export type OrganizationRoleType =
  | 'owner'
  | 'admin'
  | 'staff'
  | 'carer'
  | 'nurse';

// Permission enum (if you have specific permissions defined)
export enum Permission {
  // General permissions
  VIEW_DASHBOARD = 'view_dashboard',
  VIEW_ORGANIZATION = 'view_organization',
  EDIT_ORGANIZATION = 'edit_organization',

  // Staff management
  VIEW_STAFF = 'view_staff',
  ADD_STAFF = 'add_staff',
  EDIT_STAFF = 'edit_staff',
  REMOVE_STAFF = 'remove_staff',
  EDIT_STAFF_ROLE = 'edit_staff_role',
  EDIT_HOME_PROFILE = 'edit_home_profile',

  // Client/Resident management
  VIEW_CLIENTS = 'view_clients',
  ADD_CLIENTS = 'add_clients',
  EDIT_CLIENTS = 'edit_clients',
  REMOVE_CLIENTS = 'remove_clients',

  // Schedule management
  VIEW_SCHEDULES = 'view_schedules',
  EDIT_SCHEDULES = 'edit_schedules',

  // Timesheet management
  VIEW_TIMESHEETS = 'view_timesheets',
  EDIT_TIMESHEETS = 'edit_timesheets',
  APPROVE_TIMESHEETS = 'approve_timesheets',

  // Organization management
  LINK_ORGANIZATIONS = 'link_organizations',
  MANAGE_PARENT_COMPANY = 'manage_parent_company',

  // Financial management
  VIEW_INVOICES = 'view_invoices',
  CREATE_INVOICES = 'create_invoices',
  MANAGE_PAYMENTS = 'manage_payments',
  MANAGE_PAYROLL = 'manage_payroll',

  // Settings
  EDIT_AGENCY_SETTINGS = 'edit_agency_settings',
  EDIT_HOME_SETTINGS = 'edit_home_settings',

  // Communication
  USE_CHAT = 'use_chat',
  MANAGE_INVITATIONS = 'manage_invitations',

  // Profile management
  EDIT_PROFILE = 'edit_profile',

  // Specific views
  VIEW_HOMES = 'view_homes',
  VIEW_AGENCIES = 'view_agencies',
  VIEW_CARERS = 'view_carers',
  VIEW_NURSES = 'view_nurses',
  VIEW_RESIDENTS = 'view_residents',
  VIEW_THIRD_PARTIES = 'view_third_parties',

  // Document management
  VIEW_DOCUMENTS = 'view_documents',
  UPLOAD_DOCUMENTS = 'upload_documents',
  DELETE_DOCUMENTS = 'delete_documents',

  // Reporting
  GENERATE_REPORTS = 'generate_reports',
  VIEW_REPORTS = 'view_reports'
}
