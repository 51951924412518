import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Avatar,
  Chip,
  useTheme,
  useMediaQuery,
  styled,
  Pagination,
  IconButton,
  Tooltip
} from '@mui/material';
import { Search, Home, Business, MoreVert } from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import SearchOrganizationsModal from 'src/components/core/ui/org-search';
import { useGetLinkedOrganizationsQuery } from 'src/redux/@api/organisation';

interface Organization {
  id: number;
  name: string;
  email: string;
  avatar: string;
  type: 'agency' | 'home';
  status: 'Active' | 'Inactive';
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8]
  },
  position: 'relative',
  borderRadius: theme.shape.borderRadius
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  paddingBottom: theme.spacing(1)
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: 0
}));

const TypeIcon = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1
}));

interface StatusChipProps {
  status: 'Active' | 'Inactive';
}

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'status'
})<StatusChipProps>(({ theme, status }) => ({
  fontWeight: 'bold',
  backgroundColor:
    status === 'Active'
      ? theme.palette.success.light
      : theme.palette.error.light,
  color:
    status === 'Active' ? theme.palette.success.dark : theme.palette.error.dark
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  minHeight: `calc(100vh - ${theme.header.height})`, // Adjust this value based on your layout
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3)
}));

const LinkedOrganizationsList: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>(
    [] as Organization[]
  );
  const [page, setPage] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const userState = useAppSelector((state) => state.userState);

  const {
    data: linkedOrganizations,
    isLoading,
    isError
  } = useGetLinkedOrganizationsQuery(
    userState?.currentOrganization.type === 'agency' ? 'home' : 'agency'
  );

  useEffect(() => {
    if (linkedOrganizations && linkedOrganizations?.length > 0) {
      setOrganizations(linkedOrganizations);
    }
  }, [linkedOrganizations]);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const itemsPerPage = 6;
  const pageCount = Math.ceil(organizations.length / itemsPerPage);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false);
  };

  const getGridColumns = () => {
    if (isMobile) return 12;
    if (isTablet) return 6;
    return 4;
  };

  const currentPageData = organizations.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <ContentWrapper>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4
          }}
        >
          <Button
            variant="contained"
            startIcon={<Search />}
            onClick={handleOpenSearchModal}
            sx={{ borderRadius: '20px' }}
          >
            Search
          </Button>
        </Box>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          {currentPageData.map((org) => (
            <Grid item xs={12} sm={6} md={4} key={org.id}>
              <StyledCard>
                <StyledCardHeader
                  avatar={
                    <Avatar src={org.avatar} alt={org.name}>
                      {org.name[0]}
                    </Avatar>
                  }
                  action={
                    <Tooltip title="More options">
                      <IconButton aria-label="settings">
                        <MoreVert />
                      </IconButton>
                    </Tooltip>
                  }
                  title={
                    <Typography variant="h6" component="div">
                      {org.name}
                    </Typography>
                  }
                  subheader={org.email}
                />
                <StyledCardContent>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
                  >
                    <TypeIcon>
                      <Tooltip title={org.type === 'home' ? 'Home' : 'Agency'}>
                        {org.type === 'home' ? (
                          <Home color="primary" fontSize="small" />
                        ) : (
                          <Business color="secondary" fontSize="small" />
                        )}
                      </Tooltip>
                    </TypeIcon>
                  </Box>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        sx={{ display: 'flex', justifyContent: 'center', mt: 'auto', pb: 2 }}
      >
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          color="primary"
          size={isMobile ? 'small' : 'medium'}
        />
      </Box>

      <SearchOrganizationsModal
        open={openSearchModal}
        onClose={handleCloseSearchModal}
      />
    </ContentWrapper>
  );
};

export default LinkedOrganizationsList;
