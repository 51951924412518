import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  styled,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
  Chip
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';

import { LoadingButton } from '@mui/lab';
import { IJoinRequest } from 'src/interfaces/join-requests';
import {
  useAcceptInvitationMutation,
  useLazyGetInvitationsQuery
} from 'src/redux/@api/invitations';
import { useGetJoinRequestsQuery } from 'src/redux/@api/join-request';
import {
  useAddUserToOrganizationMutation,
  useGetMyOrgInvitationsQuery,
  useResponseToOrgInvitationMutation
} from 'src/redux/@api/organisation';
import { useLinkUserMutation } from 'src/redux/@api/auth';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[2],
  height: `calc(100vh - ${theme.header.height})`,
  display: 'flex',
  flexDirection: 'column'
}));

const ScrollableBox = styled(Box)({
  flexGrow: 1,
  overflowY: 'auto',
  marginTop: '16px' // Add some space between tabs and content
});

interface Invitation {
  _id: string;
  avatar?: string;
  companyName: string;
  email: string;
  senderAccountType: string;
  senderId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`invitation-tabpanel-${index}`}
      aria-labelledby={`invitation-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const Invitations: React.FC = () => {
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [getInvitations] = useLazyGetInvitationsQuery();
  const {
    data: joinRequests,
    isLoading: joinRequestsLoading,
    refetch
  } = useGetJoinRequestsQuery(undefined, { skip: tabValue === 1 });

  const { data: myOrgInvitations, isLoading: myOrgInvitationsLoading } =
    useGetMyOrgInvitationsQuery({ skip: tabValue === 0 });

  const [
    respondToOrgInv,
    { isLoading: respondToOrgInvLoading, error: respondToOrgInvError }
  ] = useResponseToOrgInvitationMutation();

  const [acceptInvitation] = useAcceptInvitationMutation();
  const [addUser, { isLoading: addUserLoading, error: addUserError }] =
    useAddUserToOrganizationMutation();
  const [linkUser] = useLinkUserMutation();
  const [acceptLoading, setAcceptLoading] = useState(false);

  async function fetchInvitations() {
    try {
      const response = await getInvitations(undefined).unwrap();
      setInvitations(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchInvitations();
  }, []);

  const handleAccept = async (orgId: string, userId: string, role: string) => {
    try {
      setAcceptLoading(true);
      const response = await addUser({
        organizationId: orgId,
        userId,
        role
      }).unwrap();

      setAcceptLoading(false);
      refetch();
      dispatch(showSnack({ message: 'Invitation accepted', color: 'success' }));
    } catch (error) {
      setAcceptLoading(false);
      console.error(error);
      dispatch(
        showSnack({ message: 'Failed to accept invitation', color: 'error' })
      );
    }
  };

  const handleReject = (id: string) => {
    setInvitations(invitations.filter((inv) => inv._id !== id));
    dispatch(showSnack({ message: 'Invitation rejected', color: 'error' }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleResponseToOrgInv = async (invId: string, response: boolean) => {
    try {
      const res = await respondToOrgInv({
        invitationId: invId,
        accept: response
      }).unwrap();
      console.log('Response to org invitation:', res);
      dispatch(
        showSnack({ message: 'Invitation responded', color: 'success' })
      );
    } catch (error) {
      dispatch(
        showSnack({
          message: 'Failed to respond to org invitation',
          color: 'error'
        })
      );
      console.error('Failed to respond to org invitation:', error);
    }
  };

  if (joinRequestsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledPaper>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="invitation tabs"
      >
        <Tab label="Staff Invitations" />
        <Tab label="Organization Invitations" />
      </Tabs>

      <ScrollableBox>
        <TabPanel value={tabValue} index={0}>
          <List sx={{ padding: 0 }}>
            {joinRequests?.data?.map((request: IJoinRequest) => (
              <React.Fragment key={request.id}>
                <ListItem disablePadding>
                  <StyledListItemButton>
                    <ListItemAvatar>
                      <Avatar
                        src={''}
                        alt={`${request.user?.firstName} ${request.user?.lastName}`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="subtitle1">
                            {request.user?.firstName} {request.user?.lastName}
                          </Typography>
                          <Chip
                            sx={{
                              marginLeft: 1
                            }}
                            label={request?.role || 'Unknown Role'}
                            size="small"
                          />
                        </Box>
                      }
                      secondary={
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            {request.user?.email}
                          </Typography>
                        </Box>
                      }
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: 1
                      }}
                    >
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          handleAccept(
                            request?.organization?._id,
                            request.user?._id,
                            request.role
                          )
                        }
                      >
                        Accept
                      </LoadingButton>
                      <LoadingButton
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => handleReject(request.id)}
                      >
                        Reject
                      </LoadingButton>
                    </Box>
                  </StyledListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          {joinRequests?.data?.length === 0 && (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              No staff invitations at the moment.
            </Typography>
          )}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <List sx={{ padding: 0 }}>
            {myOrgInvitations?.invitations?.map((invitation: any) => (
              <React.Fragment key={invitation._id}>
                <ListItem disablePadding>
                  <StyledListItemButton>
                    <ListItemAvatar>
                      <Avatar
                        src={invitation.avatar}
                        alt={invitation?.companyName}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="subtitle1">
                            {invitation.fromOrganization?.name}
                          </Typography>
                          <Chip
                            sx={{
                              marginLeft: 1
                            }}
                            label={invitation?.role || 'Unknown Role'}
                            size="small"
                          />
                        </Box>
                      }
                      secondary={
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            {invitation.fromOrganization?.email}
                          </Typography>
                        </Box>
                      }
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: 1
                      }}
                    >
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={acceptLoading}
                        size="small"
                        onClick={() => {
                          handleResponseToOrgInv(invitation._id, true);
                        }}
                      >
                        Accept
                      </LoadingButton>
                      <LoadingButton
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => {
                          handleResponseToOrgInv(invitation._id, false);
                        }}
                      >
                        Reject
                      </LoadingButton>
                    </Box>
                  </StyledListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          {myOrgInvitations?.invitations?.length === 0 && (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              No organization invitations at the moment.
            </Typography>
          )}
        </TabPanel>
      </ScrollableBox>
    </StyledPaper>
  );
};

export default Invitations;
