import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  useGetPadCheckAnalysisQuery,
  useGetResidentProgressQuery,
  useGetTaskCompletionRateQuery,
  useGetMealAnalysisQuery,
  useGetMealProgressDataQuery
} from 'src/redux/@api/resident';
import PadCheckAnalysis from './dashboard/pad-check';
import MealAnalysis from './dashboard/meal';

const taskTypes = ['pad check', 'meal'];

const ResidentProgressDashboard = ({ residentId }) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [taskType, setTaskType] = useState('pad check');

  const {
    data: progressData,
    isLoading: isProgressLoading,
    error: progressError
  } = useGetResidentProgressQuery({
    residentId,
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
    taskType
  });

  const {
    data: completionRate,
    isLoading: isCompletionRateLoading,
    error: completionRateError
  } = useGetTaskCompletionRateQuery({
    residentId,
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
    taskType
  });

  const {
    data: padCheckAnalysis,
    isLoading: isPadCheckAnalysisLoading,
    error: padCheckAnalysisError
  } = useGetPadCheckAnalysisQuery({
    residentId,
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0]
  });

  const {
    data: mealAnalysis,
    isLoading: isMealAnalysisLoading,
    error: mealAnalysisError
  } = useGetMealAnalysisQuery({
    residentId,
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0]
  });

  const {
    data: mealProgressData,
    isLoading: isMealProgressDataLoading,
    error: mealProgressDataError
  } = useGetMealProgressDataQuery({
    residentId,
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0]
  });

  const handleTaskTypeChange = (event) => {
    setTaskType(event.target.value);
  };

  if (
    isProgressLoading ||
    isCompletionRateLoading ||
    (taskType === 'pad check' && isPadCheckAnalysisLoading) ||
    (taskType === 'meal' &&
      (isMealAnalysisLoading || isMealProgressDataLoading))
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (
    progressError ||
    completionRateError ||
    (taskType === 'pad check' && padCheckAnalysisError) ||
    (taskType === 'meal' && (mealAnalysisError || mealProgressDataError))
  ) {
    return (
      <Typography color="error" variant="body2">
        Error fetching data. Please try again later.
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 2, m: 1 }}>
      <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
        Resident Progress Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth size="small">
            <InputLabel>Task Type</InputLabel>
            <Select
              value={taskType}
              label="Task Type"
              onChange={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleTaskTypeChange(event);
              }}
            >
              {taskTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {taskType === 'pad check' && (
          <Grid item xs={12}>
            <PadCheckAnalysis padCheckAnalysis={padCheckAnalysis} />
          </Grid>
        )}

        {taskType === 'meal' && (
          <Grid item xs={12}>
            <MealAnalysis
              mealAnalysis={mealAnalysis}
              mealProgressData={mealProgressData}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ResidentProgressDashboard;
