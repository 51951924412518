import React, { useState } from 'react';
import {
  List,
  Grid,
  Box,
  Typography,
  Avatar,
  Chip,
  Collapse,
  Button,
  Rating,
  useTheme
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  AccessTime,
  AttachMoney,
  Star,
  Comment
} from '@mui/icons-material';
import moment from 'moment';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.colors.secondary.light
  },
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  '&:hover': {
    boxShadow: theme.shadows[3]
  }
}));

const StyledChip = styled(Chip)(({ theme, color }) => ({
  fontWeight: 'bold',
  color: theme.palette.getContrastText(theme.palette[color].main)
}));

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

const TimesheetList = ({
  timesheets,
  userState,
  currentOrganization,
  onApproveTimesheet,
  onApproveWithoutReview
}) => {
  const [expanded, setExpanded] = useState([]);
  const theme = useTheme();

  const handleExpandToggle = (timesheetId) => {
    setExpanded((prev) =>
      prev.includes(timesheetId)
        ? prev.filter((id) => id !== timesheetId)
        : [...prev, timesheetId]
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      default:
        return 'default';
    }
  };

  const canApproveAndReview = (timesheet) => {
    return (
      (userState.user?.role === 'admin' || userState.user?.role === 'nurse') &&
      currentOrganization?.type === 'home' &&
      timesheet?.status === 'pending' &&
      userState.user._id !== timesheet?.carer?._id
    );
  };

  return (
    <List sx={{ padding: 0, flex: 1 }}>
      <Grid container spacing={1}>
        {timesheets.map((timesheet) => {
          const isWeekend = moment(timesheet?.shift_?.date).isoWeekday() > 5;
          let carerPay = 0;
          let homerate = 0;

          if (userState.user?.role === 'admin') {
            const userrate =
              timesheet?.shift_?.shiftPattern?.userTypeRates?.find(
                (rate) =>
                  rate.userType?.toLowerCase() === timesheet?.carer?.role
              );

            const homeRate = timesheet?.shift_?.shiftPattern?.rates?.find(
              (rate) => rate.careHomeId === timesheet?.shift_?.homeId
            );
            homerate = isWeekend
              ? homeRate?.weekendRate
              : homeRate?.weekdayRate;
            carerPay = isWeekend
              ? userrate?.weekendRate
              : userrate?.weekdayRate;
          }

          const timing = timesheet?.shift_?.shiftPattern?.timings?.find(
            (timing) => timing.careHomeId === timesheet?.shift_.homeId
          );
          const startTime = timing?.startTime;
          const endTime = timing?.endTime;

          return (
            <Grid item xs={12} sm={6} md={6} key={timesheet?._id} p={0}>
              <StyledListItem>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleExpandToggle(timesheet?._id)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                    <Avatar
                      alt={timesheet?.carer?.firstName}
                      src={timesheet?.carer?.avatar?.url}
                      sx={{ mr: 2, width: 40, height: 40 }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1" component="div">
                        {`${timesheet?.carer?.firstName} ${timesheet?.carer?.lastName}`}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {moment(timesheet?.shift_?.date).format('DD/MM/YYYY')} -{' '}
                        {timesheet?.shift_?.shiftPattern?.name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StyledChip
                        label={timesheet?.status}
                        color={getStatusColor(timesheet?.status)}
                        size="small"
                        sx={{ mr: 1, borderRadius: '10px' }}
                      />
                      {expanded.includes(timesheet?._id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </Box>
                  </Box>

                  <Collapse in={expanded.includes(timesheet?._id)}>
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: theme.colors.alpha.trueWhite[100],
                        [theme.breakpoints.down('sm')]: {
                          bgcolor: theme.colors.secondary.light
                        }
                      }}
                    >
                      <DetailItem>
                        <AccessTime />
                        <Typography variant="body2">
                          Time: {startTime} - {endTime}
                        </Typography>
                      </DetailItem>

                      {timesheet?.rating && (
                        <DetailItem>
                          <Star />
                          <Typography variant="body2">
                            Rating:{' '}
                            <Rating
                              value={timesheet?.rating}
                              readOnly
                              size="small"
                            />
                          </Typography>
                        </DetailItem>
                      )}

                      {timesheet?.review && (
                        <DetailItem>
                          <Comment />
                          <Typography variant="body2">
                            Review: {timesheet?.review}
                          </Typography>
                        </DetailItem>
                      )}

                      {userState.user?.role === 'admin' && (
                        <Box sx={{ mt: 2 }}>
                          <DetailItem>
                            <AttachMoney />
                            <Typography variant="body2">
                              Carer Pay: £{carerPay}{' '}
                              {userState.currentOrganization.type ===
                                'agency' && (
                                <span>| Home Rate: £{homerate}</span>
                              )}
                            </Typography>
                          </DetailItem>
                        </Box>
                      )}

                      {canApproveAndReview(timesheet) && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mt: 2
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => onApproveTimesheet(timesheet)}
                            sx={{ mr: 1 }}
                          >
                            Review
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                              onApproveWithoutReview(timesheet?._id)
                            }
                          >
                            Approve
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </Box>
              </StyledListItem>
            </Grid>
          );
        })}
      </Grid>
    </List>
  );
};

export default TimesheetList;
