import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Tabs,
  Tab
} from '@mui/material';
import { FilterList, Receipt } from '@mui/icons-material';
import { useAppSelector } from 'src/redux/hook';
import { useDispatch } from 'react-redux';
import { showSnack } from 'src/redux/reducers/snack/snack-slice';
import {
  useLazyGetTimesheetsQuery,
  useApproveTimesheetMutation
} from 'src/redux/@api/timesheet';

import InvoiceDialog from 'src/components/core/dialogs/shifts/invoice-dialog';
import moment from 'moment';
import TimesheetList from './timesheet-components/timesheet-list';
import FilterDialog from './timesheet-components/filter';
import RateAndReviewDialog from './timesheet-components/review';

const INITIAL_FILTER_OPTIONS = {
  homeName: '',
  startDate: moment().startOf('month').format('YYYY-MM-DD'),
  endDate: moment().endOf('month').format('YYYY-MM-DD'),
  shiftType: '',
  status: 'all'
};

const CarerTimesheets = () => {
  const [originalTimesheets, setOriginalTimesheets] = useState([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState(INITIAL_FILTER_OPTIONS);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState({
    timesheets: [],
    totalAmount: 0,
    home: {
      homeId: '',
      homeName: '',
      homeAddress: '',
      homeEmail: '',
      homePhone: ''
    }
  });
  const [rateAndReviewDialogOpen, setRateAndReviewDialogOpen] = useState(false);
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const { userState } = useAppSelector((state) => state);
  const { currentOrganization } = userState;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [getTimeSheets] = useLazyGetTimesheetsQuery();
  const [approveTimesheet] = useApproveTimesheetMutation();

  const isNurse = userState.user?.role === 'nurse';

  useEffect(() => {
    fetchTimesheets();
  }, []);

  async function fetchTimesheets() {
    setIsLoading(true);
    try {
      const response = await getTimeSheets(undefined).unwrap();
      setOriginalTimesheets(response);
      applyFilters(response, INITIAL_FILTER_OPTIONS);
    } catch (error) {
      console.error('Error fetching timesheets:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFilterChange = (field, value) => {
    setFilterOptions((prev) => ({ ...prev, [field]: value }));
  };

  const applyFilters = (timesheets, options) => {
    const filtered = timesheets.filter((timesheet) => {
      const shiftDate = moment(timesheet?.shift_?.date);
      const filterStartDate = moment(options.startDate);
      const filterEndDate = moment(options.endDate);

      return (
        (options.homeName === '' ||
          timesheet?.shift.home.company?.name
            .toLowerCase()
            .includes(options.homeName.toLowerCase())) &&
        shiftDate.isBetween(filterStartDate, filterEndDate, null, '[]') &&
        (options.shiftType === '' ||
          timesheet?.shift.shiftPattern?.name
            .toLowerCase()
            .includes(options.shiftType.toLowerCase())) &&
        (options.status === 'all' || timesheet?.status === options.status)
      );
    });
    setFilteredTimesheets(filtered);
    setFilterDialogOpen(false);
  };

  const handleApplyFilters = () => {
    applyFilters(originalTimesheets, filterOptions);
  };

  const clearAllFilters = () => {
    setFilterOptions(INITIAL_FILTER_OPTIONS);
    applyFilters(originalTimesheets, INITIAL_FILTER_OPTIONS);
    setFilterDialogOpen(false);
  };

  const handleCreateInvoice = () => {
    const approvedTimesheets = filteredTimesheets.filter(
      (timesheet) => timesheet?.status === 'approved'
    );
    let totalAmount = 0;
    const invoiceTimesheets = [];

    for (const timesheet of approvedTimesheets) {
      try {
        const homeRate = timesheet?.shift_?.shiftPattern?.rates.find(
          (rate) => rate.careHomeId === timesheet?.shift_.homeId
        );
        const isWeekend = moment(timesheet?.shift_?.date).isoWeekday() > 5;
        const hourlyPay = isWeekend
          ? homeRate?.weekendRate
          : homeRate?.weekdayRate;
        const timing = timesheet?.shift_?.shiftPattern?.timings.find(
          (timing) => timing.careHomeId === timesheet?.shift_.homeId
        );
        const startTime = moment(timing.startTime, 'HH:mm');
        const endTime = moment(timing.endTime, 'HH:mm');
        let shiftDuration;
        if (endTime.isBefore(startTime)) {
          shiftDuration = moment.duration(
            endTime.add(1, 'day').diff(startTime)
          );
        } else {
          shiftDuration = moment.duration(endTime.diff(startTime));
        }
        const hours = shiftDuration.asHours();
        const amount = hourlyPay * hours;
        totalAmount += amount;

        invoiceTimesheets.push({
          ...timesheet,
          hourlyRate: hourlyPay,
          hours: hours,
          amount: amount,
          shiftDate: timesheet?.shift_?.date,
          shiftType: timesheet?.shift_?.shiftPattern?.name,
          carerName: `${timesheet?.carer?.firstName} ${timesheet?.carer?.lastName}`,
          homeName: timesheet?.home?.name
        });
      } catch (error) {
        console.error('Error processing timesheet for invoice:', error);
      }
    }

    setInvoiceData({
      timesheets: invoiceTimesheets,
      totalAmount,
      home: {
        homeName: invoiceTimesheets[0]?.home?.name || '',
        homeId: invoiceTimesheets[0]?.home?._id || '',
        homeAddress: invoiceTimesheets[0]?.home?.address || '',
        homeEmail: invoiceTimesheets[0]?.home?.email || '',
        homePhone: invoiceTimesheets[0]?.home?.phone || ''
      }
    });
    setInvoiceDialogOpen(true);
  };

  const handleApproveTimesheet = (timesheet) => {
    setCurrentTimesheet(timesheet);
    setRateAndReviewDialogOpen(true);
  };

  const handleRateAndReview = async (rating, review) => {
    try {
      await approveTimesheet({
        timesheetId: currentTimesheet?._id,
        rating,
        review
      }).unwrap();
      setFilteredTimesheets((prevTimesheets) =>
        prevTimesheets.map((t) =>
          t._id === currentTimesheet?._id ? { ...t, status: 'approved' } : t
        )
      );
      setRateAndReviewDialogOpen(false);
      dispatch(
        showSnack({
          message: 'Timesheet approved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({ message: 'Error approving timesheet', color: 'error' })
      );
    }
  };

  const handleApproveWithoutReview = async (timesheetId) => {
    try {
      await approveTimesheet({
        timesheetId: timesheetId,
        rating: null,
        review: null
      }).unwrap();
      setFilteredTimesheets((prevTimesheets) =>
        prevTimesheets.map((t) =>
          t._id === timesheetId ? { ...t, status: 'approved' } : t
        )
      );
      dispatch(
        showSnack({
          message: 'Timesheet approved successfully',
          color: 'success'
        })
      );
    } catch (error) {
      dispatch(
        showSnack({
          message: error.data.message || 'Error approving timesheets',
          color: 'error'
        })
      );
    }
  };

  const isCreateInvoiceDisabled = () => {
    const hasFilters =
      JSON.stringify(filterOptions) !== JSON.stringify(INITIAL_FILTER_OPTIONS);
    const hasApprovedTimesheets = filteredTimesheets.some(
      (timesheet) => timesheet?.status === 'approved'
    );
    return !hasFilters || !hasApprovedTimesheets;
  };

  const getPersonalTimesheets = () => {
    return filteredTimesheets.filter(
      (timesheet) => timesheet?.carer?._id === userState.user._id
    );
  };

  const getStaffTimesheets = () => {
    return filteredTimesheets.filter(
      (timesheet) => timesheet?.carer?._id !== userState.user._id
    );
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          height: `calc(100vh - ${theme.header.height})`,
          [theme.breakpoints.up('sm')]: {
            height: `calc(100vh - ${theme.header.height} - 30px)`
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
        <Typography
          variant="body1"
          sx={{
            marginTop: 2,
            animation: `fadeIn 1s ease-in`,
            animationFillMode: 'forwards',
            opacity: 0
          }}
        >
          Fetching timesheets...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, height: `calc(100vh - ${theme.header.height})` }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          mb: 2,
          height: '60px'
        }}
      >
        <IconButton
          onClick={() => setFilterDialogOpen(true)}
          color={
            JSON.stringify(filterOptions) !==
            JSON.stringify(INITIAL_FILTER_OPTIONS)
              ? 'primary'
              : 'default'
          }
        >
          <FilterList />
        </IconButton>
        {userState?.currentOrganization.type === 'agency' &&
          userState.user.role === 'admin' && (
            <Button
              startIcon={<Receipt />}
              variant="contained"
              color="primary"
              onClick={handleCreateInvoice}
              sx={{ ml: 2 }}
              disabled={isCreateInvoiceDisabled()}
            >
              Create Invoice
            </Button>
          )}
      </Box>

      {isNurse ? (
        <>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            sx={{ mb: 2, height: '40px' }}
          >
            <Tab label="Personal" />
            <Tab label="Staff" />
          </Tabs>
          {tabValue === 0 ? (
            <TimesheetList
              timesheets={getPersonalTimesheets()}
              userState={userState}
              currentOrganization={currentOrganization}
              onApproveTimesheet={handleApproveTimesheet}
              onApproveWithoutReview={handleApproveWithoutReview}
            />
          ) : (
            <TimesheetList
              timesheets={getStaffTimesheets()}
              userState={userState}
              currentOrganization={currentOrganization}
              onApproveTimesheet={handleApproveTimesheet}
              onApproveWithoutReview={handleApproveWithoutReview}
            />
          )}
        </>
      ) : (
        <TimesheetList
          timesheets={filteredTimesheets}
          userState={userState}
          currentOrganization={currentOrganization}
          onApproveTimesheet={handleApproveTimesheet}
          onApproveWithoutReview={handleApproveWithoutReview}
        />
      )}

      <FilterDialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        filterOptions={filterOptions}
        onFilterChange={handleFilterChange}
        onApplyFilters={handleApplyFilters}
        onClearFilters={clearAllFilters}
      />

      <InvoiceDialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        timesheets={invoiceData.timesheets}
        totalAmount={invoiceData.totalAmount}
        home={invoiceData.home}
        selectedStartDate={filterOptions.startDate}
        selectedEndDate={filterOptions.endDate}
      />

      <RateAndReviewDialog
        open={rateAndReviewDialogOpen}
        onClose={() => setRateAndReviewDialogOpen(false)}
        timesheet={currentTimesheet}
        onSubmit={handleRateAndReview}
      />
    </Box>
  );
};

export default CarerTimesheets;
