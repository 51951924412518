import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Paper,
  Chip,
  Autocomplete,
  CircularProgress,
  Avatar,
  IconButton,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ICarePlan, IResident } from 'src/interfaces/resident';
import { apiHostname } from 'src/api/api';
import axios from 'axios';
import {
  useCreateCarePlanMutation,
  useGetCarePlanQuery,
  useGetResidentQuery,
  useUpdateCarePlanMutation,
  useUpdateResidentMutation
} from 'src/redux/@api/resident';

interface ResidentProfileProps {
  residentId: string;
}

const ResidentProfile: React.FC<ResidentProfileProps> = ({ residentId }) => {
  const theme = useTheme();

  const { data: resident, isLoading: isLoadingResident } =
    useGetResidentQuery(residentId);
  const { data: carePlan, isLoading: isLoadingCarePlan } =
    useGetCarePlanQuery(residentId);
  const [updateResident, { isLoading: isUpdatingResident }] =
    useUpdateResidentMutation();
  const [updateCarePlan, { isLoading: isUpdatingCarePlan }] =
    useUpdateCarePlanMutation();
  const [createCarePlan, { isLoading: isCreatingCarePlan }] =
    useCreateCarePlanMutation();

  const [isUploading, setIsUploading] = useState(false);
  const [personalInfo, setPersonalInfo] = useState<IResident>({
    firstName: '',
    lastName: '',
    dateOfBirth: new Date(),
    roomNumber: '',
    admissionDate: new Date(),
    medicalConditions: [],
    allergies: [],
    group: null,
    emergencyContact: {
      name: '',
      relationship: '',
      phone: ''
    },
    tasks: [],
    taskSummary: {
      upcoming: 0,
      pending: 0,
      overdue: 0,
      total: 0,
      urgency: 'white'
    },
    profilePictureUrl: '',
    carePlan: '' as any
  });
  const [carePlanInfo, setCarePlanInfo] = useState<Partial<ICarePlan>>({
    mobilityAssistance: '',
    feedingRequirements: '',
    medicationNeeds: '',
    allergies: '',
    mentalHealth: '',
    behaviouralNotes: '',
    specialInstructions: ''
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  useEffect(() => {
    if (resident) {
      setPersonalInfo(resident);
    }
  }, [resident]);

  useEffect(() => {
    if (carePlan) {
      setCarePlanInfo(carePlan);
    }
  }, [carePlan]);

  const handlePersonalInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setPersonalInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEmergencyContactChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setPersonalInfo((prev) => ({
      ...prev,
      emergencyContact: {
        ...prev.emergencyContact,
        [name]: value
      }
    }));
  };

  const handleCarePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCarePlanInfo((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setPersonalInfo((prev) => ({
        ...prev,
        dateOfBirth: date
      }));
    }
  };

  const handleSavePersonalInfo = async () => {
    try {
      const residentData: Partial<IResident> = {
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        dateOfBirth: personalInfo.dateOfBirth,
        roomNumber: personalInfo.roomNumber,
        admissionDate: personalInfo.admissionDate,
        medicalConditions: personalInfo.medicalConditions,
        allergies: personalInfo.allergies,
        group: personalInfo.group,
        emergencyContact: personalInfo.emergencyContact,
        profilePictureUrl: personalInfo.profilePictureUrl
      };
      await updateResident({ id: residentId, residentData }).unwrap();
      setSnackbar({
        open: true,
        message: 'Personal information saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving personal info:', error);
      setSnackbar({
        open: true,
        message: 'Error saving personal information',
        severity: 'error'
      });
    }
  };

  const handleSaveCarePlan = async () => {
    try {
      if (carePlan) {
        await updateCarePlan({ residentId, ...carePlanInfo }).unwrap();
      } else {
        await createCarePlan({ residentId, ...carePlanInfo }).unwrap();
      }
      setSnackbar({
        open: true,
        message: 'Care plan saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving care plan:', error);
      setSnackbar({
        open: true,
        message: 'Error saving care plan',
        severity: 'error'
      });
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(
          `${apiHostname}/api/v1/pictures/resident/${residentId}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            timeout: 30000
          }
        );

        if (response.data.success) {
          setPersonalInfo((prev) => ({
            ...prev,
            profilePictureUrl: response.data.data.avatarUrl
          }));
          setSnackbar({
            open: true,
            message: 'Profile picture uploaded successfully',
            severity: 'success'
          });
        } else {
          throw new Error(response.data.error);
        }
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        setSnackbar({
          open: true,
          message: 'Error uploading profile picture',
          severity: 'error'
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  if (isLoadingResident || isLoadingCarePlan) {
    return <CircularProgress />;
  }

  if (!resident) {
    return <Typography>No resident data available</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ py: 1 }}>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar
                  src={personalInfo.profilePictureUrl}
                  sx={{ width: 200, height: 200, mb: 2 }}
                />
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="icon-button-file"
                  type="file"
                  onChange={handleImageUpload}
                  disabled={isUploading}
                />
                <label htmlFor="icon-button-file">
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    disabled={isUploading}
                    startIcon={
                      isUploading ? (
                        <CircularProgress size={24} />
                      ) : (
                        <PhotoCameraIcon />
                      )
                    }
                  >
                    {isUploading ? 'Uploading...' : 'Upload Photo'}
                  </Button>
                </label>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" gutterBottom>
                Personal Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={personalInfo.firstName || ''}
                    onChange={handlePersonalInfoChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={personalInfo.lastName || ''}
                    onChange={handlePersonalInfoChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Date of Birth"
                    value={personalInfo.dateOfBirth || null}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Room Number"
                    name="roomNumber"
                    value={personalInfo.roomNumber || ''}
                    onChange={handlePersonalInfoChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Group"
                    name="group"
                    value={personalInfo.group || ''}
                    onChange={handlePersonalInfoChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Medical Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    options={[]}
                    freeSolo
                    value={personalInfo.medicalConditions || []}
                    onChange={(_, newValue) =>
                      setPersonalInfo(
                        (prev) =>
                          ({ ...prev, medicalConditions: newValue } as any)
                      )
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          key={index}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Medical Conditions"
                        placeholder="Add medical condition"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    options={[]}
                    freeSolo
                    value={personalInfo.allergies || []}
                    onChange={(_, newValue) =>
                      setPersonalInfo(
                        (prev) => ({ ...prev, allergies: newValue } as any)
                      )
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          key={index}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Allergies"
                        placeholder="Add allergy"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Emergency Contact
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={personalInfo.emergencyContact?.name || ''}
                    onChange={handleEmergencyContactChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Relationship"
                    name="relationship"
                    value={personalInfo.emergencyContact?.relationship || ''}
                    onChange={handleEmergencyContactChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={personalInfo.emergencyContact?.phone || ''}
                    onChange={handleEmergencyContactChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  onClick={handleSavePersonalInfo}
                  variant="contained"
                  color="primary"
                  disabled={isUpdatingResident}
                >
                  {isUpdatingResident
                    ? 'Saving...'
                    : 'Save Personal Information'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Care Plan
          </Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Mobility Assistance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Mobility Assistance Details"
                name="mobilityAssistance"
                value={carePlanInfo.mobilityAssistance || ''}
                onChange={handleCarePlanChange}
                placeholder="E.g., Uses a walker, requires assistance with transfers, uses a hoist"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Feeding Requirements</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Feeding Requirements"
                name="feedingRequirements"
                value={carePlanInfo.feedingRequirements || ''}
                onChange={handleCarePlanChange}
                placeholder="E.g., Requires assistance with feeding, risk of choking, dietary restrictions"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Medication Needs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Medication Needs"
                name="medicationNeeds"
                value={carePlanInfo.medicationNeeds || ''}
                onChange={handleCarePlanChange}
                placeholder="E.g., List of current medications, administration instructions"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Allergies (Care Plan)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Allergies"
                name="allergies"
                value={carePlanInfo.allergies || ''}
                onChange={handleCarePlanChange}
                placeholder="List any known allergies and reactions"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Mental Health</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Mental Health Notes"
                name="mentalHealth"
                value={carePlanInfo.mentalHealth || ''}
                onChange={handleCarePlanChange}
                placeholder="E.g., Dementia type, cognitive status, mood disorders"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Behavioural Notes</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Behavioural Notes"
                name="behaviouralNotes"
                value={carePlanInfo.behaviouralNotes || ''}
                onChange={handleCarePlanChange}
                placeholder="E.g., Triggers for distress, calming techniques, social preferences"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Special Instructions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Special Instructions"
                name="specialInstructions"
                value={carePlanInfo.specialInstructions || ''}
                onChange={handleCarePlanChange}
                placeholder="Any additional care instructions or important notes"
              />
            </AccordionDetails>
          </Accordion>
          <Box sx={{ mt: 2 }}>
            <Button
              onClick={handleSaveCarePlan}
              variant="contained"
              color="primary"
              disabled={isUpdatingCarePlan || isCreatingCarePlan}
            >
              {isUpdatingCarePlan || isCreatingCarePlan
                ? 'Saving...'
                : carePlan
                ? 'Update Care Plan'
                : 'Create Care Plan'}
            </Button>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </LocalizationProvider>
  );
};

export default ResidentProfile;
